import {
  IconCaptureText,
  IconEffectsColorCycle,
  IconFilter,
  IconLayers,
} from "@veneer/core";
import { useEffect, useState } from "react";

export const MainTitle = ({ backgrounds, bgIndex, userHasToPay }) => {
  const [data, setData] = useState();
  const [index, setIndex] = useState();

  useEffect(() => {
    if (bgIndex) {
      setIndex(bgIndex - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bgIndex]);

  useEffect(() => {
    if (backgrounds) {
      setData(backgrounds[index]?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, backgrounds]);
  if (data?.type === 1) {
    return (
      <div
        style={{
          background:
            "linear-gradient(146.55deg,rgba(211, 224, 255, 0.1185) 9.49%, rgba(62, 154, 252, 0.144258) 29.35%, rgba(172, 211, 245, 0.15) 80.11%)",
          boxShadow: "0px 4px 24px rgba(87, 87, 87, 0.3)",
          backdropFilter: "blur(5px)",
          // borderRadius: "40px 0px",
        }}
        className="w-full min-[1150px]:w-2/3 flex flex-col justify-center itesm-center h-full px-12"
      >
        <div className="flex">
          <p className="text-white title-large">
            {data?.title}{" "}
            <span className="text-[#3AB4EB]">
              {userHasToPay ? data?.userHasToPayStyleTitle : data?.styleTitle}
            </span>
          </p>
        </div>
        <div className="flex my-[50px]">
          <p className="text-white subtitle-large">
            {userHasToPay ? data?.userHasToPaySubtitle : data.subtitle}{" "}
          </p>
        </div>
      </div>
    );
  } else if (data?.type === 2) {
    return (
      <div
        style={{
          background:
            "linear-gradient(146.55deg,rgba(211, 224, 255, 0.1185) 9.49%, rgba(62, 154, 252, 0.144258) 29.35%, rgba(172, 211, 245, 0.15) 80.11%)",
          boxShadow: "0px 4px 24px rgba(87, 87, 87, 0.3)",
          backdropFilter: "blur(5px)",
        }}
        className="w-full min-[1150px]:w-2/3 flex flex-col justify-center itesm-center h-full px-12"
      >
        <div className="flex">
          <p className="text-white title-large">
            {userHasToPay ? data?.userHasToPayTitle : data?.title}{" "}
            <span className="font-bold">
              {userHasToPay ? data?.userHasToPayStyleTitle : data?.styleTitle}
            </span>
          </p>
        </div>
        <div className="flex my-[50px] w-full border">
          <div className="flex flex-col w-full border">
            <div className="flex min-[320px]:flex-col md:flex-row w-full">
              <div className="flex items-center mb-6 w-full min-[1150px]:w-1/2">
                <div
                  className="rounded-[50%] p-2"
                  style={{
                    background:
                      "linear-gradient(54.52deg, #3AB4EB 46.84%, rgba(58, 97, 235, 0.97) 100.03%)",
                    boxShadow: "0px 4px 14px #34A9D3",
                  }}
                >
                  <IconEffectsColorCycle filled color="colorWhite" size={36} />
                </div>
                <p className="ml-[15px] mr-1 text-white text-[18px] leading-6 font-bold">
                  {data?.icon1Text}
                </p>
              </div>
              <div className="flex items-center mb-6 w-full min-[1150px]:w-1/2">
                <div
                  className="rounded-[50%] p-2"
                  style={{
                    background:
                      "linear-gradient(54.52deg, #3AB4EB 46.84%, rgba(58, 97, 235, 0.97) 100.03%)",
                    boxShadow: "0px 4px 14px #34A9D3",
                  }}
                >
                  <IconLayers filled color="colorWhite" size={36} />
                </div>
                <p className="ml-[15px] mr-1 text-white text-[18px] leading-6 font-bold">
                  {data?.icon2Text}
                </p>
              </div>
            </div>
            <div className="flex min-[320px]:flex-col md:flex-row w-full">
              <div className="flex items-center mb-6 w-1/2">
                <div
                  className="rounded-[50%] p-2"
                  style={{
                    background:
                      "linear-gradient(54.52deg, #3AB4EB 46.84%, rgba(58, 97, 235, 0.97) 100.03%)",
                    boxShadow: "0px 4px 14px #34A9D3",
                  }}
                >
                  <IconFilter filled color="colorWhite" size={36} />
                </div>
                <p className="ml-[15px] mr-1 text-white text-[18px] leading-6 font-bold">
                  {data?.icon3Text}
                </p>
              </div>
              <div className="flex items-center mb-6 w-1/2">
                <div
                  className="rounded-[50%] p-2"
                  style={{
                    background:
                      "linear-gradient(54.52deg, #3AB4EB 46.84%, rgba(58, 97, 235, 0.97) 100.03%)",
                    boxShadow: "0px 4px 14px #34A9D3",
                  }}
                >
                  <IconCaptureText filled color="colorWhite" size={36} />
                </div>
                <p className="ml-[15px] mr-1 text-white text-[18px] leading-6 font-bold">
                  {data?.icon4Text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!data) {
    return (
      <div className="flex flex-col justify-center itesm-center h-full px-12"></div>
    );
  }
};
