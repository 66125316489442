// eslint-disable-next-line no-unused-vars
import { firebase } from "./firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref as refStorage,
} from "firebase/storage";
import { get, getDatabase, ref, update } from "firebase/database";

const dbFirestore = getFirestore();
const storage = getStorage();
const dbRealTime = getDatabase();
const url = process.env.REACT_APP_URL;

let sessionsCollection = "sessions";

export const checkIfSessionIdInAllSessionsDB = async (sessionId) => {
  //check if session id exists in all sessions in real time db
  const info = await get(ref(dbRealTime, "/allSessions/" + sessionId));
  return info.val();
};

export const getWebValuesDB = async () => {
  const info = await get(ref(dbRealTime, "/webValues"));
  return info.val();
};

export const getWebValuesV2DB = async () => {
  const info = await get(ref(dbRealTime, "/webValuesV2"));
  return info.val();
};

export const getWebValuesV3DB = async () => {
  const info = await get(ref(dbRealTime, "/webValuesV3"));
  return info.val();
};

export const getUserSessionInfoDB = async (sessionId) => {
  const collectionRef = doc(dbFirestore, `${sessionsCollection}/`, sessionId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const updateIsPaidSessionTypeADB = async (sessionId) => {
  if (sessionId) {
    await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
      paid: true,
      status: "paid",
    });
    return sessionId;
  }
};

export const updateIsPaidSessionTypeBDB = async (sessionId) => {
  if (sessionId) {
    await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
      paid: true,
      status: "unassigned",
    });
    return sessionId;
  }
};

export const saveDataDB = async (email, isOutWorkingHours, userHasToPay) => {
  //to know if user is internal or external
  let emailSplit = email.split("@");
  let userType = "";
  if (emailSplit[1] === "hp.com") {
    userType = "Internal";
  } else {
    userType = "External";
  }
  //to get number of user conversions
  const coll = collection(dbFirestore, "sessions");
  const q = query(coll, where("userEmail", "==", email));
  const snapshot = await getCountFromServer(q);
  let userConversion = snapshot.data().count;

  let createdAtDate = new Date();

  // let day = createdAtDate.getDate();
  // let month = createdAtDate.getMonth() + 1;
  // let year = createdAtDate.getFullYear();
  // let hour = createdAtDate.getHours();
  // let minutes = createdAtDate.getMinutes();
  // let seconds = createdAtDate.getSeconds();
  // if (day < 10) {
  //   day = "0" + day;
  // }
  // if (month < 10) {
  //   month = "0" + month;
  // }
  // if (hour < 10) {
  //   hour = "0" + hour;
  // }
  // if (minutes < 10) {
  //   minutes = "0" + minutes;
  // }
  // if (seconds < 10) {
  //   seconds = "0" + seconds;
  // }

  // let filename = day + month + year + "_" + hour + minutes + seconds;

  try {
    let newData = {
      status: "unassigned",
      code: parseInt(
        (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
      ),
      ratingForm: [],
      commentForm: "",
      ratingFormDownload: [],
      commentFormDownload: "",
      requestImprovedVersion: false,
      userEmail: email,
      paid: false,
      tags: [],
      technician: "",
      expectedDeadline: null,
      urlPlan: "",
      urlCad: "",
      urlSVG: "",
      uploadedCAD: false,
      createdAt: createdAtDate,
      // fileName: filename,
      convertedAt: null,
      previewAt: null,
      feedbackAt: null,
      downloadAt: null,
      improvedVersionAt: null,
      startedWorkingAt: null,
      comment: "",
      userType: userType,
      hasInvoice: false,
      fileCategory: "",
      contentCategory: "",
      complexity: "",
      projectType: "A", //we need it to show finish session button in tech dashboard
      userConversion: userConversion + 1,
      isOutWorkingHours: isOutWorkingHours,
      //add field if user access to preview and field if email is sended to user
      hasAccessed: false,
      reminderSent: false,
      userHasToPay: userHasToPay,
    };

    let docRef;
    //add doc to firestore with the session data, add the id to the doc and return the doc id
    docRef = await addDoc(collection(dbFirestore, `${sessionsCollection}`), {
      ...newData,
    });
    await updateDoc(doc(dbFirestore, `${sessionsCollection}`, docRef.id), {
      id: docRef.id,
    });
    return { id: docRef.id, code: newData.code };
  } catch (error) {
    console.log("error", error);
    return null;
  }
};

export const updateTimestampDB = async (sessionId, fieldTimestamp) => {
  if (sessionId) {
    await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
      [fieldTimestamp]: new Date(),
    });
    return sessionId;
  }
};

export const updateSessionUrlPlanDB = async (sessionId, urlPlan) => {
  if (sessionId) {
    await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
      urlPlan,
    });
    return sessionId;
  }
};

export const getImgStorageDB = async (section, bgName) => {
  if (section && bgName) {
    return await getDownloadURL(refStorage(storage, `${section}/${bgName}`))
      .then(async (url) => {
        return url;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const sendUploadedEmailToUserDB = async (email, sessionId) => {
  const urlEmail = `${url}/sendFileUploadedEmail?email=${email}&session=${sessionId}`;
  const response = await fetch(urlEmail, {
    method: "POST",
  });
  if (response.ok) {
    console.log("Uploaded file email sended");
  } else {
    console.log("Error, uploaded file email not sended");
  }
};

export const sendNewSessionEmailDB = async () => {
  const urlNewSession = `${url}/sendNewSession`;
  const response = await fetch(urlNewSession, {
    method: "POST",
  });
  if (response.ok) {
    console.log("New session email sended");
  } else {
    console.log("Error, new session email not sended");
  }
};

export const sendNewPaymentEmailDB = async () => {
  const urlEmail = `${url}/sendNewPayment`;
  const response = await fetch(urlEmail, {
    method: "POST",
  });
  if (response.ok) {
    console.log("New payment email sended");
  } else {
    console.log("Error, new payment email not sended");
  }
};

export const updateHasInvoiceDB = async (sessionId) => {
  await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
    hasInvoice: true,
  });
};

export const updateInStep2DB = async (
  sessionId,
  ratingFormDownload,
  commentFormDownload,
  ratingFormImproved,
  commentFormImproved,
  requestImprovedVersion
) => {
  await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
    ratingForm: ratingFormImproved ? ratingFormImproved : [],
    commentForm: commentFormImproved ? commentFormImproved : "",
    ratingFormDownload: ratingFormDownload ? ratingFormDownload : [],
    commentFormDownload: commentFormDownload ? commentFormDownload : "",
    requestImprovedVersion,
    feedbackAt: new Date(),
  });
};

export const updateHasAccessedDB = async (sessionId) => {
  await updateDoc(doc(dbFirestore, `${sessionsCollection}`, sessionId), {
    hasAccessed: true,
  });
};

export const getAmountDB = async () => {
  const info = await get(ref(dbRealTime, "/projectValues"));
  return info.val().amount;
};

export const getInvoiceNumberDB = async () => {
  const info = await get(ref(dbRealTime, "/projectValues"));
  return info.val().invoiceNumber;
};

export const updateInvoiceNumberDB = async (invoiceNumber) => {
  await update(ref(dbRealTime, "/projectValues"), {
    invoiceNumber: invoiceNumber + 1,
  });
};

export const getCurrencyDB = async () => {
  const info = await get(ref(dbRealTime, "/projectValues"));
  return info.val().currency;
};

export const deleteSessionDB = async (code, email) => {
  //get session where code and email match
  const coll = collection(dbFirestore, "sessions");
  const q = query(
    coll,
    where("code", "==", code),
    where("userEmail", "==", email)
  );
  const querySnapshot = await getDocs(q);
  let sessionId;
  querySnapshot.forEach((doc) => {
    sessionId = doc.id;
  });
  await deleteDoc(doc(dbFirestore, "sessions", sessionId));
};
