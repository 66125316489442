import mixpanel from "mixpanel-browser";

export const NavbarButtons = ({ webValues, setShowInfo, gadParam }) => {
  return (
    <div className="flex w-full justify-center items-center">
      <div
        className="flex flex-col items-center justify-center mx-4"
        onClick={() => {
          setShowInfo("button1");
          mixpanel.track("clicked on about us", { gad: gadParam });
        }}
      >
        <p className="fontSize3 label cursor-pointer text-primaryBlue">
          {webValues?.strings?.button1Title}
        </p>
      </div>
      <div
        className="flex flex-col items-center justify-center mx-4"
        onClick={() => {
          setShowInfo("button2");
          mixpanel.track("clicked on contact us", { gad: gadParam });
        }}
      >
        <p className="fontSize3 label cursor-pointer text-primaryBlue">
          {webValues?.strings?.button2Title}
        </p>
      </div>
      <div
        className="flex flex-col items-center justify-center mx-4"
        onClick={() => {
          setShowInfo("button3");
          mixpanel.track("clicked on FAQs", { gad: gadParam });
        }}
      >
        <p className="fontSize3 label cursor-pointer text-primaryBlue">
          {webValues?.strings?.button3Title}
        </p>
      </div>
    </div>
  );
};
