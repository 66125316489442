import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import md5 from "md5";
import {
  checkIfSessionIdInAllSessionsDB,
  getImgStorageDB,
  getWebValuesDB,
  getWebValuesV2DB,
  getWebValuesV3DB,
} from "../lib/db";
import { MainComponent } from "./main-component";
import { PolicyModal } from "./policy-modal";
import { Navbar } from "./navbar";
import { BackgroundDiv } from "./background-div";
import { NavbarButtonsInfo } from "./navbar-buttons-info";
import Preview from "./preview";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { Button, ProgressIndicator } from "@veneer/core";
import { MainTitle } from "./main-title";
import { getCookie } from "../lib/utils";
import { ErrorModal } from "./error-modal";

export const User = ({ isMobile, isTest }) => {
  //important --> webValuesVersion 1 or 2
  const [webValuesVersion] = useState(
    process.env.REACT_APP_WEB_VALUES_VERSION
      ? parseInt(process.env.REACT_APP_WEB_VALUES_VERSION)
      : 2
  );
  const [gadParam, setGadParam] = useState("none");
  const [sessionId, setSessionId] = useState();
  const [sessionInfo, setSessionInfo] = useState();
  const [webValues, setWebValues] = useState();
  const [webValuesV2, setWebValuesV2] = useState();
  const [webValuesV3, setWebValuesV3] = useState();
  const [backgrounds, setBackgrounds] = useState();
  const [backgroundsImage, setBackgroundsImage] = useState();
  const [backgroundsArray, setBackgroundsArray] = useState();
  const [backgroundsV2, setBackgroundsV2] = useState();
  const [backgroundsImageV2, setBackgroundsImageV2] = useState();
  const [backgroundsArrayV2, setBackgroundsArrayV2] = useState();
  const [showInfo, setShowInfo] = useState();
  const [showPolicyModal, setShowPolicyModal] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState();
  const [sessionState, setSessionState] = useState(0);
  const [bgIndex, setBgIndex] = useState(0);
  const [invoice, setInvoice] = useState();
  const [newSessionDataTypeB, setNewSessionDataTypeB] = useState();

  //calculate times for session state == 2
  const [analyzedProgress, setAnalyzedProgress] = useState();
  const [cleanedProgress, setCleanedProgress] = useState();
  const [recognizingProgress, setRecognizingProgress] = useState();
  const [now, setNow] = useState(new Date().getTime());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [percentage, setPercentage] = useState();
  const [limit] = useState(50);
  const [cookies, setCookies] = useState();
  const [cookiesFunctionality, setCookiesFunctionality] = useState();
  const [cookiesTargeting, setCookiesTargeting] = useState();
  const [cookiesTracking, setCookiesTracking] = useState();
  const [cookiesStrictlyNecessary, setCookiesStrictlyNecessary] = useState();
  const [showModalSessionExpired, setShowModalSessionExpired] = useState(false);
  const [isDownloadFlow, setIsDownloadFlow] = useState(false);
  const [userHasToPay, setUserHasToPay] = useState(false);
  //sessionState:
  //0 -> form upload file
  //1 -> file uploaded
  //2 -> session created
  //3 -> drawing ready
  //4 -> pay&download (stripe)
  //5 -> is paid
  //6 -> isDownloaded
  //7 -> sendInvoiceAfterPay
  const sessionCollection = "sessions";
  const navigate = useNavigate();
  const dbFirestore = getFirestore();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionIdUrl = urlParams.get("sessionId");
    const gadUrl = urlParams.get("gad");
    if (window.location.pathname === "/data-policy") {
      setShowPolicyModal(true);
    }
    if (gadUrl) {
      setGadParam(gadUrl);
    }
    if (sessionIdUrl) {
      checkIfSessionIdInAllSessions(sessionIdUrl);
      setSessionId(sessionIdUrl);
    } else {
      // if (gadUrl) {
      //   navigate(`/user?gad=${gadUrl}`);
      // }
    }

    getWebValues();
    getWebValuesV2();
    getWebValuesV3();
    document.getElementById("update-cookies-div").style.display = "block";

    setInterval(() => {
      let cookiesT = getCookie("cookie_consent_level");
      if (cookiesT) {
        setCookies(JSON.parse(cookiesT));
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfSessionIdInAllSessions = async (sessionIdUrl) => {
    //check if session id is in realtime db in child allSessions
    let oldSession = await checkIfSessionIdInAllSessionsDB(sessionIdUrl);
    if (oldSession) {
      setShowModalSessionExpired(true);
    }
  };

  useEffect(() => {
    if (webValuesV3) {
      setUserHasToPay(webValuesV3.userHasToPay);
    }
  }, [webValuesV3]);

  useEffect(() => {
    if (cookies) {
      setCookiesFunctionality(cookies.functionality);
      setCookiesTargeting(cookies.targeting);
      setCookiesTracking(cookies.tracking);
      setCookiesStrictlyNecessary(cookies["strictly-necessary"]);
    }
  }, [
    cookies,
    cookiesFunctionality,
    cookiesStrictlyNecessary,
    cookiesTargeting,
    cookiesTracking,
  ]);

  useEffect(() => {
    if (sessionId && sessionInfo && gadParam === "none") {
      navigate(`/user?sessionId=${sessionId}`);
    } else if (sessionId && sessionInfo && gadParam && gadParam !== "none") {
      navigate(`/user?sessionId=${sessionId}&gad=${gadParam}`);
    } else if (!sessionId && !sessionInfo && gadParam === "none") {
      navigate(`/user`);
      setSessionState(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, sessionInfo, gadParam]);

  useEffect(() => {
    if (webValues && webValues.backgrounds && webValuesVersion === 1) {
      setBackgrounds(Object.entries(webValues?.backgrounds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webValues, webValuesVersion]);

  useEffect(() => {
    if (webValuesV2 && webValuesV2.backgrounds && webValuesVersion === 2) {
      setBackgroundsV2(webValuesV2?.backgrounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webValuesV2, webValuesVersion]);

  useEffect(() => {
    if (backgrounds) {
      getBackgroundsStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgrounds]);

  useEffect(() => {
    if (backgroundsV2) {
      getBackgroundsStorageV2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundsV2]);

  useEffect(() => {
    if (sessionId) {
      getUserSessionInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (
      backgroundsImage?.bgImages &&
      Object.values(backgroundsImage?.bgImages).length > 0
    ) {
      setBackgroundsArray(Object.values(backgroundsImage?.bgImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundsImage]);

  useEffect(() => {
    if (
      backgroundsImageV2?.bgImages &&
      Object.values(backgroundsImageV2?.bgImages).length > 0
    ) {
      setBackgroundsArrayV2(Object.values(backgroundsImageV2?.bgImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundsImageV2]);

  useEffect(() => {
    if (sessionId && sessionInfo) {
      if (sessionInfo?.userEmail) {
        mixpanel.identify(md5(sessionInfo.userEmail));
      }
      if (
        sessionInfo?.isOutWorkingHours &&
        sessionInfo?.status === "unassigned"
      ) {
        setSessionState(10);
        return;
      }
      if (
        (!sessionState || sessionState === 0) &&
        sessionInfo.status === "unassigned"
      ) {
        mixpanel.track("enter while waiting", {
          gad: gadParam,
        });
        setSessionState(2);
      } else if (
        (!sessionState || sessionState === 0) &&
        sessionInfo.status === "assigned"
      ) {
        mixpanel.track("enter while waiting", {
          gad: gadParam,
        });
        setSessionState(2);
      } else if (
        (!sessionState || sessionState === 0) &&
        sessionInfo.status === "finished"
      ) {
        mixpanel.track("enter when done", {
          gad: gadParam,
        });
        setSessionState(3);
      } else if (
        (!sessionState || sessionState === 0) &&
        sessionInfo.status === "paid"
      ) {
        setSessionState(8);
      } else if (!sessionState || sessionState === 0) {
        mixpanel.track("enter while waiting", {
          gad: gadParam,
        });
        setSessionState(1);
      }
      if (sessionInfo.status) {
        switch (sessionInfo.status) {
          case "unassigned":
            if (
              new Date().getTime() >
              sessionInfo?.createdAt?.seconds * 1000 + 10000
            ) {
              setSessionState(2);
            } else {
              setSessionState(1);
            }

            break;
          case "assigned":
            setSessionState(2);
            break;
          case "finished":
            if (
              sessionState === 3 ||
              sessionState === 4 ||
              sessionState === 5 ||
              sessionState === 6 ||
              sessionState === 7 ||
              sessionState === 11
            ) {
              return;
            }
            setSessionState(8);
            // if (sessionInfo.requestImprovedVersion) {
            //   setSessionState(7);
            // } else if (
            //   !sessionInfo.requestImprovedVersion &&
            //   sessionInfo.commentForm !== "" &&
            //   sessionInfo.commentForm
            // ) {
            //   setSessionState(8);
            // } else {
            //   setSessionState(
            //     sessionState === 7 ? 7 : sessionState === 4 ? 4 : 3
            //   );
            // }
            break;
          case "paid":
            console.log("sessionState", sessionState);
            // setSessionState(
            //   sessionState === 5 ? 5 : sessionState === 7 ? 7 : 12
            // );
            break;
          case "error":
            setSessionState(9);
            break;
          case "refund":
            setSessionState(0);
            if (gadParam && gadParam !== "none") {
              navigate(`/user?gad=${gadParam}`);
            } else if (gadParam === "none") {
              navigate(`/user`);
            }
            setSessionId();
            setSessionInfo();
            break;
          default:
            setSessionState(0);
            break;
        }
      }
    } else {
      if (gadParam) {
        mixpanel.track("enter in web", {
          gad: gadParam,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo]);

  const getBackgroundsStorage = async () => {
    let bgImages = {};
    backgrounds?.map(async (bg) => {
      if (bg) {
        bgImages[bg[0]] = {
          url: await getImgStorageDB("backgrounds", bg[1]),
        };
        setBackgroundsImage({ ...backgroundsImage, bgImages });
      }
    });
  };

  const getBackgroundsStorageV2 = async () => {
    let bgImages = {};
    backgroundsV2?.map(async (bg, key) => {
      if (bg) {
        bgImages[key] = {
          data: bg,
          url: await getImgStorageDB("backgrounds", bg.src),
        };
        setBackgroundsImageV2({ ...backgroundsImageV2, bgImages });
      }
    });
  };

  const getWebValues = async () => {
    setWebValues(await getWebValuesDB());
  };

  const getWebValuesV2 = async () => {
    setWebValuesV2(await getWebValuesV2DB());
  };

  const getWebValuesV3 = async () => {
    setWebValuesV3(await getWebValuesV3DB());
  };

  const getUserSessionInfo = async () => {
    onSnapshot(
      doc(dbFirestore, `${sessionCollection}`, sessionId),
      { includeMetadataChanges: true },
      async (doc) => {
        if (doc.data()) {
          setSessionInfo(doc.data());
          // mixpanel.track("enter in web", { gad: gadParam });
        } else {
          if (gadParam && gadParam !== "none") {
            navigate(`/user?gad=${gadParam}`);
          } else if (gadParam === "none") {
            navigate(`/user}`);
          }
          // mixpanel.track("enter in web", { gad: gadParam });
          setSessionId();
          setSessionInfo();
        }
      }
    );
    // setSessionInfo(await getUserSessionInfoDB(sessionId));
  };

  return webValues && webValuesV3 && webValuesV2 ? (
    <div className="h-screen w-full">
      <div>
        <Navbar
          navigate={navigate}
          setSessionState={setSessionState}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          webValues={webValues}
          setShowInfo={setShowInfo}
          gadParam={gadParam}
        />
      </div>

      <div
        style={{
          background: !backgroundsArray && !backgroundsArrayV2 && "#0278ab",
          height: "calc(100vh - 76px)",
        }}
        className="w-full relative overflow-y-hidden overflow-x-hidden h-screen"
      >
        {/* <div className="h-[calc(100vh-124px)] w-full overflow-y-auto relative"> */}
        {((backgroundsArray && backgroundsArray.length > 0) ||
          (backgroundsArrayV2 && backgroundsArrayV2.length > 0)) &&
          !sessionInfo && (
            <BackgroundDiv
              webValuesVersion={webValuesVersion}
              backgroundsArray={
                webValuesVersion === 1 ? backgroundsArray : backgroundsArrayV2
              }
              webValues={webValuesV2}
              setBgIndex={setBgIndex}
            />
          )}

        {showInfo && (
          <NavbarButtonsInfo
            webValues={webValues}
            showInfo={showInfo}
            setShowInfo={setShowInfo}
          />
        )}

        {showPolicyModal && (
          <PolicyModal
            setShowPolicyModal={setShowPolicyModal}
            showPolicyModal={showPolicyModal}
            webValues={webValues}
          />
        )}

        <div
          className="flex w-full h-full justify-between flex-wrap overflow-y-auto overflow-x-hidden"
          style={{
            display: showInfo ? "none" : "flex",
            // padding: !isMobile ? "0px" : "0px",
            // paddingTop: isMobile ? "40px" : "0px",
            // paddingLeft: isMobile ? "40px" : "0px",
            // paddingRight: isMobile ?  "40px" : "0px",
          }}
        >
          {!sessionInfo && (
            <MainTitle userHasToPay={webValuesV3.userHasToPay} backgrounds={backgroundsArrayV2} bgIndex={bgIndex + 1} />
          )}

          {sessionInfo && (
            <div
              style={{
                background:
                  sessionState === 3 ||
                  sessionState === 4 ||
                  sessionState === 5 ||
                  sessionState === 6 ||
                  sessionState === 7 ||
                  sessionState === 11 ||
                  sessionState === 12
                    ? "#B7B7B7"
                    : "#212121",
              }}
              className="w-full min-[1150px]:w-2/3 flex flex-col justify-center itesm-center h-full"
            >
              {(sessionState === 3 ||
                sessionState === 4 ||
                sessionState === 5 ||
                sessionState === 6 ||
                sessionState === 7 ||
                sessionState === 11 ||
                sessionState === 12) && (
                <Preview
                  session={sessionInfo}
                  showPreviewModal={showPreviewModal}
                  setShowPreviewModal={setShowPreviewModal}
                  setSessionState={setSessionState}
                  gadParam={gadParam}
                />
              )}
              {(sessionState === 1 || sessionState === 8) && (
                <div className="w-full text-center text-[24px]">
                  <Button
                    customStyle={{ color: "#0278AB" }}
                    appearance="ghost"
                    disabled
                    loading
                  ></Button>
                </div>
              )}
              {sessionState === 2 &&
                (analyzedProgress < 100 || cleanedProgress === 0) && (
                  <div className="w-full text-center text-[24px] px-4">
                    <ProgressIndicator
                      appearance="linear"
                      color="colorHpBlue6"
                      behavior="determinate"
                      value={analyzedProgress}
                    />
                    <p className="text-white mt-3 text-[24px]">
                      {analyzedProgress < 100
                        ? webValuesV3.strings.bar1Text
                        : "Drawing analized"}
                    </p>
                    <p className="mt-10 text-[16px] text-white">
                      {webValuesV3.strings.barsWaitText}
                    </p>
                  </div>
                )}
              {sessionState === 2 &&
                cleanedProgress > 0 &&
                cleanedProgress < 100 && (
                  <div className="w-full text-center px-4">
                    <ProgressIndicator
                      appearance="linear"
                      color="colorHpBlue6"
                      behavior="determinate"
                      value={cleanedProgress}
                    />
                    <p className="text-white mt-3 text-[24px]">
                      {webValuesV3.strings.bar2Text}
                    </p>
                    <p className="mt-10 text-[16px] text-white">
                      {webValuesV3.strings.barsWaitText}
                    </p>
                  </div>
                )}
              {sessionState === 2 &&
                cleanedProgress === 100 &&
                recognizingProgress <= 100 && (
                  <div className="w-full text-center px-4">
                    <ProgressIndicator
                      appearance="linear"
                      color="colorHpBlue6"
                      behavior="determinate"
                      value={recognizingProgress}
                    />
                    <p className="text-white mt-3 text-[24px]">
                      {recognizingProgress < 100
                        ? webValuesV3.strings.bar3Text
                        : "Preview generated"}
                    </p>
                  </div>
                )}
            </div>
          )}

          <MainComponent
            isTest={isTest}
            isMobile={isMobile}
            setShowPolicyModal={setShowPolicyModal}
            setSessionId={setSessionId}
            sessionId={sessionId}
            sessionInfo={sessionInfo}
            navigate={navigate}
            sessionState={sessionState}
            setSessionState={setSessionState}
            showPreviewModal={showPreviewModal}
            setShowPreviewModal={setShowPreviewModal}
            webValues={webValues}
            webValuesV3={webValuesV3}
            gadParam={gadParam}
            invoice={invoice}
            setInvoice={setInvoice}
            newSessionDataTypeB={newSessionDataTypeB}
            setNewSessionDataTypeB={setNewSessionDataTypeB}
            setSessionInfo={setSessionInfo}
            analyzedProgress={analyzedProgress}
            setAnalyzedProgress={setAnalyzedProgress}
            cleanedProgress={cleanedProgress}
            setCleanedProgress={setCleanedProgress}
            recognizingProgress={recognizingProgress}
            setRecognizingProgress={setRecognizingProgress}
            now={now}
            setNow={setNow}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            percentage={percentage}
            setPercentage={setPercentage}
            limit={limit}
            setIsDownloadFlow={setIsDownloadFlow}
            isDownloadFlow={isDownloadFlow}
            userHasToPay={userHasToPay}
          />
        </div>
      </div>
      {showModalSessionExpired && (
        <ErrorModal
          setSessionState={setSessionState}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          navigate={navigate}
          gadParam={gadParam}
          text={"Session expired, please upload your file again."}
          title={"Session expired"}
          buttonText={"OK"}
          setShowModalError={setShowModalSessionExpired}
        />
      )}
    </div>
  ) : null;
};
