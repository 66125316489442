import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "@veneer/core";
import { useEffect, useState } from "react";
import { getAmountDB, getCurrencyDB, getInvoiceNumberDB } from "../lib/db";
import { MainComponentStripeForm } from "./main-component-stripe-form";

export const MainComponentPayAndDonwload = ({
  isTest,
  sessionId,
  setSessionId,
  sessionInfo,
  setSessionState,
  gadParam,
  invoice,
  setInvoice,
  projectType,
  newSessionDataTypeB,
  navigate,
  isMobile,
  webValues,
  sessionState,
  setSessionInfo
}) => {
  const [clientSecret, setClientSecret] = useState();
  const [options, setOptions] = useState();
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [publicKey] = useState(process.env.REACT_APP_STRIPE);
  const stripePromise = loadStripe(publicKey);

  useEffect(() => {
    getAmount();
    getCurrency();
    getInvoiceNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (amount && currency) {
      getClientSecret();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, currency]);

  useEffect(() => {
    if (clientSecret) {
      setOptions({
        clientSecret: clientSecret,
      });
    }
  }, [clientSecret]);

  const getInvoiceNumber = async () => {
    setInvoiceNumber(parseInt(await getInvoiceNumberDB()));
  };

  const getAmount = async () => {
    setAmount(parseInt(await getAmountDB()));
  };

  const getCurrency = async () => {
    setCurrency(await getCurrencyDB());
  };

  const getClientSecret = async () => {
    const url = process.env.REACT_APP_URL;
    let urlStripe;
    if (isTest) {
      urlStripe = `${url}/createStripeClientTokenTest2/`;
    } else {
      urlStripe = `${url}/createStripeClientToken/`;
    }
    // urlStripe = `${url}/createStripeClientToken/`;
    const response = await fetch(urlStripe, {
      method: "POST",
      body: JSON.stringify({ amount: amount * 100, currency: currency }),
    });
    const { clientSecret } = await response.json();
    if (clientSecret) {
      setClientSecret(clientSecret);
    } else {
      setClientSecret();
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 95px)",
      }}
      className="flex flex-col items-center justify-center py-6"
    >
      <div>
        {clientSecret && options && stripePromise && (
          <Elements options={options} stripe={stripePromise}>
            <MainComponentStripeForm
              isTest={isTest}
              sessionId={sessionId}
              sessionInfo={sessionInfo}
              setSessionInfo={setSessionInfo}
              setSessionState={setSessionState}
              gadParam={gadParam}
              invoice={invoice}
              setInvoice={setInvoice}
              amount={amount}
              currency={currency}
              projectType={projectType}
              newSessionDataTypeB={newSessionDataTypeB}
              navigate={navigate}
              setSessionId={setSessionId}
              invoiceNumber={invoiceNumber}
              getInvoiceNumber={getInvoiceNumber}
              isMobile={isMobile}
              webValues={webValues}
            />
          </Elements>
        )}
        {(!clientSecret || !options || !stripePromise) && (
          <div className="w-full flex justify-center items-center">
            <Button appearance="ghost" loading></Button>
          </div>
        )}
      </div>
    </div>
  );
};
