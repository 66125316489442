import { useDropzone } from "react-dropzone";
import {
  Button,
  Checkbox,
  IconCheckmarkCircle,
  IconDocument,
  IconUpload,
  IconWarning,
  IconX,
  IconXCircle,
  ProgressIndicator,
  TextBox,
} from "@veneer/core";
import {
  getStorage,
  ref as refStorage,
  uploadBytesResumable,
} from "firebase/storage";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import mixpanel from "mixpanel-browser";
import md5 from "md5";
import {
  deleteSessionDB,
  saveDataDB,
  sendNewSessionEmailDB,
  sendUploadedEmailToUserDB,
  updateSessionUrlPlanDB,
} from "../lib/db";
import { isMobile, isTablet } from "react-device-detect";
import { checkOutHoursWorkingTime } from "../lib/utils";
import { ErrorModal } from "./error-modal";

export const MainComponentUploadForm = ({
  isTest,
  setShowPolicyModal,
  sessionId,
  sessionInfo,
  setSessionInfo,
  setSessionState,
  setSessionId,
  navigate,
  gadParam,
  webValues,
  userHasToPay
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const storage = getStorage();
  const [email, setEmail] = useState();
  const [checkbox, setCheckbox] = useState();
  const [file, setFile] = useState();
  const [existFileProgress, setExistFileProgress] = useState();
  const [fileProgress, setFileProgress] = useState(0);
  const [uploadTaskState, setUploadTaskState] = useState();
  const [isLoading, setIsLoading] = useState();
  const [showModalError, setShowModalError] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/tiff": [".tiff"],
      "image/bmp": [".bmp"],
    },
    maxFiles: 1,
  });
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (data.policy && file && email) {
      let fileName = file?.name;
      let fileExtension = fileName?.split(".").pop();
      let progress;
      let startHourLimit = webValues?.strings?.startHourLimit;
      let endHourLimit = webValues?.strings?.endHourLimit;
      //to know if is Out Working Hours
      let isOutWorkingHours = checkOutHoursWorkingTime(
        new Date(),
        startHourLimit,
        endHourLimit
      );
      let saveDataSessionId = await saveDataDB(email, isOutWorkingHours, userHasToPay);
      if (saveDataSessionId.id) {
        fileName = saveDataSessionId.id + "." + fileExtension;
        const imageRef = refStorage(storage, "usersPlans/" + fileName);
        const uploadTask = uploadBytesResumable(imageRef, file);
        setUploadTaskState(uploadTask);
        uploadTask.on("state_changed", async (snapshot) => {
          setExistFileProgress(true);
          progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFileProgress(progress);
          if (parseInt(progress) === 100) {
            await updateSessionUrlPlanDB(saveDataSessionId.id, fileName);
            setExistFileProgress();
            reset();
            if (gadParam && gadParam !== "none") {
              navigate(
                `/user?sessionId=${saveDataSessionId.id}&gad=${gadParam}`
              );
            } else {
              navigate(`/user?sessionId=${saveDataSessionId.id}`);
            }
            setSessionState(1);
            setSessionId(saveDataSessionId.id);
            mixpanel.identify(md5(email));
            // mixpanel.people.set({ gad: gadParam });
            mixpanel.track("Upload file", { gad: gadParam });
            await sendUploadedEmailToUserDB(email, saveDataSessionId.id);
            if (!isTest) {
              await sendNewSessionEmailDB();
            }
            if (!isOutWorkingHours) {
              setTimeout(() => {
                setSessionState(2);
              }, 10000);
            }
          }
        });
      } else {
        setShowModalError(true);
        //delete session if not saved with ID
        await deleteSessionDB(saveDataSessionId.code, email);
      }
    }
    setIsLoading();
  };

  const cancelUploadToStorage = () => {
    if (uploadTaskState) {
      document.getElementById("input-file").value = null;
      setFile();
      setExistFileProgress();
      setFileProgress();
      uploadTaskState.cancel();
    }
  };

  const checkEmail = (email) => {
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (emailValid) {
      setEmail(email);
    } else {
      setEmail();
    }
  };

  return (
    <form
      style={{
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
        height: "calc(100% - 95px)",
      }}
      className="flex flex-col justify-center w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      {!sessionId && !sessionInfo && (
        <div className="flex flex-col items-center justify-between py-6 h-full">
          <TextBox
            type="email"
            id="input-email"
            onChange={(text) => checkEmail(text)}
            required
            value={email}
            placeholder={webValues?.strings?.uploadFormEmailPlaceholder}
          />

          {!existFileProgress && (
            <div
              style={{
                borderRadius: "12px",
                border: !file ? "1px dashed #DBDBDB" : "",
                background: !isDragActive ? "#FFF" : "#DBDBDB",
              }}
              className="flex justify-center items-center w-full h-full mt-4 cursor-pointer"
            >
              {!file && (
                <div
                  className="cursor-pointer flex w-full justify-center items-center h-full"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />

                  <div className="flex items-center justify-center h-[56px] w-[56px] bg-[#EDEEFE] rounded-[50%]">
                    <IconUpload color="colorHpBlue6" size={36} />
                  </div>
                  <div className="flex flex-col ml-4">
                    <p className="text-[#212121]">
                      {webValues?.strings?.uploadFormFileTitle}
                    </p>
                    <p className="underline text-[#212121]">
                      {webValues?.strings?.uploadFormFileSubtitle}
                    </p>
                  </div>
                </div>
              )}

              {file && (
                <div className="flex w-full h-full justify-between items-center  cursor-default">
                  <div
                    className="w-full flex items-center p-5"
                    style={{
                      borderRadius: "12px",
                      border: "1px solid #DBDBDB",
                    }}
                  >
                    <div className="w-full flex items-center ">
                      <div className="flex items-center">
                        <IconDocument color="colorHpBlue6" size={36} />
                      </div>
                      <div className="flex flex-col ml-4">
                        <p
                          style={{ fontSize: isMobile ? "14px" : "16px" }}
                          className="text-[#212121] font-bold"
                        >
                          {file.name.slice(0, 28)}
                        </p>
                        <p
                          style={{ fontSize: isMobile ? "14px" : "16px" }}
                          className="text-[#212121]"
                        >
                          {Math.round((file.size / 1000000) * 100) / 100 +
                            " MB - " +
                            file.name.slice(-3).toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="cursor-pointer" onClick={() => setFile()}>
                      <IconX />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {existFileProgress && file && (
            <div className="flex justify-center items-center rounded-xl w-full mt-4">
              <div className="flex w-full items-center justify-between">
                <div
                  className="flex flex-col"
                  style={{
                    width: parseInt(fileProgress) < 100 ? "90%" : "100%",
                  }}
                >
                  <p className="label-bold">
                    {parseInt(fileProgress) < 100
                      ? "Uploading file..."
                      : "File successfully uploaded."}
                  </p>
                  <div
                    className="w-full my-1"
                    style={{ borderRadius: "2px", background: "#DBDBDB" }}
                  >
                    <ProgressIndicator
                      appearance="linear"
                      color="colorHpBlue6"
                      behavior="determinate"
                      value={parseInt(fileProgress)}
                    />
                  </div>
                  <div className="flex w-full justify-between items-center mt-1">
                    <p className="label">File {file?.name.substring(15, 0)}</p>
                    <p className="label">{parseInt(fileProgress) + "%"}</p>
                  </div>
                </div>
                {parseInt(fileProgress) < 100 && (
                  <div className="w-[10%] ml-2">
                    <IconXCircle
                      type="button"
                      color="colorHpBlue6"
                      onClick={() => cancelUploadToStorage()}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="w-full my-4">
            <div className="flex items-center">
              {!fileProgress || parseInt(fileProgress) < 100 ? (
                <IconWarning filled color="colorGray5" />
              ) : (
                <IconCheckmarkCircle filled color="colorGreen6" />
              )}

              <p className="caption-small ml-1.5 mt-1">
                {webValues?.strings?.uploadFormInfo1Text}
              </p>
            </div>
            <div className="flex items-center mt-3">
              {!fileProgress || parseInt(fileProgress) < 100 ? (
                <IconWarning filled color="colorGray5" />
              ) : (
                <IconCheckmarkCircle filled color="colorGreen6" />
              )}
              <p className="caption-small ml-1.5">
                {webValues?.strings?.uploadFormInfo2Text}
              </p>
            </div>
          </div>
          {!existFileProgress && (
            <div className="w-full py-2">
              <hr className="h-[1px] bg-[#DBDBDB] border-0" />
            </div>
          )}
          {!existFileProgress && (
            <div className="w-full pt-4 pb-2 flex items-center">
              <Checkbox
                {...register("policy", { required: true })}
                onChange={() => setCheckbox(!checkbox)}
                value={checkbox}
                checked={checkbox}
                id="input-checkbox"
              />

              <p className="caption-small ml-2">
                {webValues?.strings?.uploadFormCheckboxText}{" "}
                <span
                  onClick={() => setShowPolicyModal(true)}
                  className="underline cursor-pointer"
                >
                  {webValues?.strings?.uploadFormCheckboxLink}
                </span>
              </p>
            </div>
          )}

          {!existFileProgress && (
            <div
              style={{ paddingBottom: isMobile || isTablet ? "40px" : "0px" }}
              className="mt-4 w-full"
            >
              {!isLoading && (
                <Button
                  className="w-full button-style-1"
                  disabled={email && checkbox && file ? false : true}
                  type="submit"
                >
                  {webValues?.strings?.uploadFormButton}
                </Button>
              )}
            </div>
          )}
          {isLoading && (
            <div
              style={{ paddingBottom: isMobile || isTablet ? "40px" : "0px" }}
              className="mt-4 w-full"
            >
              <Button className="w-full" appearance="ghost" loading></Button>
            </div>
          )}
        </div>
      )}
      {showModalError && (
        <ErrorModal
          setSessionState={setSessionState}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          navigate={navigate}
          gadParam={gadParam}
          text={"An error has occurred, please try again."}
          title={"Error!"}
          buttonText={"Try again"}
          setShowModalError={setShowModalError}
        />
      )}
    </form>
  );
};
