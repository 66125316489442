import { Button, TextArea, TextBox } from "@veneer/core";
import { useState } from "react";

export const NavbarButtonsInfo2 = ({ setShowInfo }) => {
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [comment, setComment] = useState();
  const [isLoading, setIsLoading] = useState();
  const sendData = async () => {
    setIsLoading(true);
    if (email && comment && name && company) {
      //local
      // const url = "http://localhost:5000/plan-to-cad/europe-west1";
      const url = process.env.REACT_APP_URL;
      const urlContact = `${url}/sendContactEmailToAdmin`;
      const response = await fetch(urlContact, {
        method: "POST",
        headers: new Headers().append("Content-type", "application/json"),
        responseType: "text",
        body: JSON.stringify({ email, name, company, comment }),
      });
      console.log("response", response);
      if (response.ok) {
        setShowInfo();
      } else {
        setShowInfo();
        console.log("Error to comment");
      }
    } else {
      console.log("Error");
    }
    setIsLoading();
  };
  return (
    <div>
      <p className="body my-6">
        If you have any doubts or questions please fill in the form or send us
        an email at <span className="text-[#0278AB]">info@plantocad.com</span>
      </p>
      <div className="my-2">
        <TextBox
          onChange={(e) => {
            setEmail(e);
          }}
          required
          value={email}
          placeholder="Your Email"
        />
      </div>
      <div className="my-2">
        <TextBox
          onChange={(e) => {
            setName(e);
          }}
          required
          value={name}
          placeholder="Name"
        />
      </div>
      <div className="my-2">
        <TextBox
          onChange={(e) => {
            setCompany(e);
          }}
          required
          value={company}
          placeholder="Company name"
        />
      </div>
      <div className="my-2">
        <TextArea
          id="text-area-contact-form"
          onChange={(e) => {
            setComment(e);
          }}
          required
          value={comment}
          placeholder="Comment"
        />
      </div>
      {!isLoading && (
        <div className="flex my-4 justify-end">
          <Button
            appearance="secondary"
            onClick={() => setShowInfo()}
            type="button"
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: "8px" }}
            onClick={sendData}
            type="button"
          >
            Submit
          </Button>
        </div>
      )}
      {isLoading && (
        <div className="flex my-4 justify-end">
          <Button appearance="ghost" loading></Button>
        </div>
      )}
    </div>
  );
};
