import { ImprovedVersionItem } from "./improved-version-item";

export const ImprovedVersion = ({
  webValues,
  setUserResponse,
  userResponse,
  isDownloadFlow,
}) => {
  return (
    <div className="w-full">
      <p className="text-[16px] mt-6">
        {isDownloadFlow
          ? webValues?.strings.improvedDownloadVersionSubtitle
          : webValues?.strings?.improvedVersionSubtitle}
      </p>
      <div className="mt-4 mb-6">
        <div className="w-full flex">
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={1}
            value={webValues?.strings?.improvedVersionItem1}
          />
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={2}
            value={webValues?.strings?.improvedVersionItem2}
          />
        </div>
        <div className="w-full flex mt-4">
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={3}
            value={webValues?.strings?.improvedVersionItem3}
          />
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={4}
            value={webValues?.strings?.improvedVersionItem4}
          />
        </div>
        <div className="w-full flex mt-4">
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={5}
            value={webValues?.strings?.improvedVersionItem5}
          />
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={6}
            value={webValues?.strings?.improvedVersionItem6}
          />
        </div>
        <div className="w-full flex mt-4">
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={7}
            value={webValues?.strings?.improvedVersionItem7}
          />
          <ImprovedVersionItem
            setUserResponse={setUserResponse}
            userResponse={userResponse}
            number={8}
            value={webValues?.strings?.improvedVersionItem8}
          />
        </div>
      </div>
    </div>
  );
};
