import { Button, Modal } from "@veneer/core";

export const ErrorModal = ({
  setSessionState,
  setSessionId,
  setSessionInfo,
  navigate,
  gadParam,
  text,
  title,
  buttonText,
  setShowModalError,
}) => {
  const resetSession = () => {
    if (setShowModalError) {
      setShowModalError(false);
    }
    setSessionState(0);
    setSessionId();
    setSessionInfo();
    if (gadParam && gadParam !== "none") {
      navigate(`/user?gad=${gadParam}`);
    } else {
      navigate(`/user`);
    }
  };

  return (
    <Modal
      align="start"
      id="modal-data-policy"
      show={true}
      title={title}
      closeButton
      onClose={() => resetSession()}
    >
      <div className="flex flex-col">
        <p className="body">{text}</p>

        <div className="mt-6 w-fit">
          <Button
            type="button"
            className="w-full button-style-1"
            onClick={() => resetSession()}
            appearance="primary"
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
