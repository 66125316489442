import mixpanel from "mixpanel-browser";
import { isMobile } from "react-device-detect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { User } from "./components/user";


function App() {
  const url = window.location.origin;
  let isTest = false;
  if (url.includes("plantocad")) {
    console.log("env: prod");
    mixpanel.init(process.env.REACT_APP_MIXPANEL, {
      disable_persistence: true,
      debug: false,
    });
    mixpanel.set_config({ persistence: "localStorage" });
  } else {
    console.log("env: test");
    isTest = true;

    mixpanel.init("f17a400278fdf2c5ee599a56784f199f", {
      disable_persistence: true,
      debug: true,
    });
    // mixpanel.set_config({ persistence: "localStorage" });
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<User isTest={isTest} />} />
        <Route
          path="/"
          element={<User isMobile={isMobile} isTest={isTest} />}
        />
        <Route
          path="/user"
          element={<User isMobile={isMobile} isTest={isTest} />}
        />
        <Route
          path="/data-policy"
          element={<User isMobile={isMobile} isTest={isTest} />}
        />
        <Route path="*" element={<User />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
