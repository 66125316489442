import { NavbarButtons } from "./navbar-buttons";

export const Navbar = ({
  navigate,
  webValues,
  setShowInfo,
  setSessionState,
  setSessionId,
  setSessionInfo,
  gadParam,
}) => {
  const resetSession = () => {
    setSessionState(0);
    setSessionId();
    setSessionInfo();
    if (gadParam && gadParam !== "none") {
      navigate(`/user?gad=${gadParam}`);
    } else {
      navigate(`/user`);
    }
  };
  return (
    <div className="w-full flex items-center justify-between bg-white h-[76px] py-6 px-4">
      <div onClick={resetSession} className="flex items-center cursor-pointer">
        <img src="/assets/logo-navbar.svg" alt="Plan to CAD" />
        <p className="ml-4  subtitle-regular">Plan to CAD</p>
      </div>
      <div className="flex justity-center items-center">
        <NavbarButtons
          webValues={webValues}
          setShowInfo={setShowInfo}
          gadParam={gadParam}
        />
      </div>
    </div>
  );
};
