import { useEffect, useState } from "react";

export const NavbarButtonsInfo3 = ({ webValues, projectType }) => {
  const [faqs, setFaqs] = useState();
  useEffect(() => {
    if (webValues) {
      let string =
        projectType === "A"
          ? webValues?.strings?.button3ContentA
          : webValues?.strings?.button3ContentB;
      let faqsT = string?.split("QUESTION: ");
      faqsT?.shift();
      let faqsArray = [];
      faqsT?.map((q) => faqsArray.push(q?.split("ANSWER: ")));
      setFaqs(faqsArray);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webValues]);

  return (
    <div>
      {faqs &&
        faqs?.map((q, key) => (
          <div key={key}>
            <p className="title-small mt-6">{q[0]}</p>
            <p className="body mt-4">{q[1]}</p>
            {key + 1 < faqs?.length && (
              <div className="mt-6">
                <hr className="h-[2px] bg-[#DBDBDB] w-full" />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
