import { Button, IconCheckmarkCircle } from "@veneer/core";
import { isMobile } from "react-device-detect";

export const MainComponentFileUploaded = ({ webValues }) => {
  return (
    <div
      style={{
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
        height: "calc(100% - 95px)",
      }}
      className="flex flex-col w-full py-6 justify-center items-center h-full"
    >
      <IconCheckmarkCircle className="mt-7" size={48} color="colorGreen6" />
      <p className="text-[24px] mt-3 text-center w-[90%]">
        {webValues?.strings?.fileUploadedTitle}
      </p>
      <p className="caption mt-2 text-center w-[90%]">
        {webValues?.strings?.fileUploadedSubtitle}
      </p>
      <p className="caption mt-2 text-center w-[90%]">
        {webValues?.strings?.fileUploadedSubtitle2}
      </p>
      <div className="mt-4 mb-8 w-[90%] flex justify-center items-center">
        <Button
          customStyle={{ color: "#0278AB" }}
          appearance="ghost"
          disabled
          loading
        ></Button>
      </div>
    </div>
  );
};
