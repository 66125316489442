export const AccurateEmotisItem = ({
  value,
  number,
  setUserResponse,
  userResponse,
}) => {
  return (
    <div className="my-6">
      <div
        onClick={() => {
          if (!userResponse.includes(value)) {
            setUserResponse([value]);
          } else {
            setUserResponse([]);
          }
        }}
        className="flex items-center ml-[-8px] cursor-pointer w-fit "
      >
        <img
          style={{
            border: userResponse.includes(value) ? "1px solid #4759F5" : "",
            borderRadius: "50%",
            opacity: userResponse.includes(value) && 1,
          }}
          src={`/assets/accurate-${number}.svg`}
          className="mr-3 cursor-pointer w-9 h-9 p-1 opacity-50 hover:opacity-100"
          alt={value}
        />
        <p className="text-[#212121] cursor-pointer">{value}</p>
      </div>
    </div>
  );
};
