import { IconX } from "@veneer/core";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { NavbarButtonsInfo1 } from "./navbar-buttons-info-1";
import { NavbarButtonsInfo2 } from "./navbar-buttons-info-2";
import { NavbarButtonsInfo3 } from "./navbar-buttons-info-3";

export const NavbarButtonsInfo = ({
  webValues,
  showInfo,
  setShowInfo,
  projectType,
}) => {
  const [content, setContent] = useState();

  useEffect(() => {
    if (showInfo === "button1") {
      setContent({
        title: webValues?.strings?.button1Title,
        content: webValues?.strings?.button1Content,
      });
    }
    if (showInfo === "button2") {
      setContent({
        title: webValues?.strings?.button2Title,
        content: webValues?.strings?.button2Content,
      });
    }
    if (showInfo === "button3") {
      setContent({
        title: webValues?.strings?.button3Title,
        content:
          projectType === "A"
            ? webValues?.strings?.button3ContentA
            : webValues?.strings?.button3ContentB,
      });
    }
    if (showInfo === "policy") {
      setContent({
        title: "Data policy",
        content: `The undersigned states and declares that the attached document does not violate any intellectual property right or any sensitive industrial data, expressly exonerating Nacar studio, S.L. of any responsibility in relation to such matters.     By clicking this checkbox, you provide consent to Nacar Design to use your contact information to take part in the ‘Plan to CAD’ research. The provided material will be shared internally between researchers with whom “Plan to CAD” project collaborates. All the information provided for this study will be treated conﬁdentially and for private use only, by Plan to CAD. under the General Data Protection Regulation (GDPR).        Our website is hosted on the XXX. Your data may be stored through XXX data storage, databases. This data is stored on secure servers behind a firewall.
            The CAD drawings or images shared by you in this platform might be shared with “Plan to CAD” third parties with the sole objective of testing and improving the solution currently under development, and avoiding sharing your personal data. No information will be shared with third parties for any purposes beyond this scope.  
            We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.If you would like to: access, correct, amend or delete any personal information we have about you, you are invited to contact us at info@nacardesign.com.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInfo]);

  return (
    <div className="flex flex-wrap h-full">
      {!isMobile && (
        <div
          style={{
            width: isMobile ? "100%" : "50%",
            paddingBottom: isMobile && "65px",
          }}
          className="flex flex-col px-[72px] pt-[65px]"
        >
          <div
            className="py-6 px-8"
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.8)",
              borderRadius: "16px",
            }}
          >
            <p className="title-large">We deliver only the best quality.</p>
            <p className="title-regular mt-6">
              That's why the vectorization process might take up to 4hours.
            </p>
            <p className="subtitle-large mt-6">
              Preview first, and pay if you’re happy with the results.
            </p>
          </div>
        </div>
      )}
      <div
        style={{ width: isMobile ? "100%" : "50%" }}
        className="flex flex-col bg-white px-[56px] pt-[40px] overflow-y-auto"
      >
        <IconX style={{ cursor: "pointer" }} onClick={() => setShowInfo()}>
          X
        </IconX>

        <p className="title-regular mt-[40px]">{content?.title}</p>
        {/* <p className="body mt-6">{content?.content}</p> */}
        {showInfo === "button1" && (
          <NavbarButtonsInfo1 content={content?.content} />
        )}
        {showInfo === "button2" && (
          <NavbarButtonsInfo2 setShowInfo={setShowInfo} />
        )}
        {showInfo === "button3" && (
          <NavbarButtonsInfo3 webValues={webValues} projectType={projectType} />
        )}
      </div>
    </div>
  );
};
