import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { Button } from "@veneer/core";
import mixpanel from "mixpanel-browser";
import { updateHasAccessedDB } from "../lib/db";

import TagManager from "react-gtm-module";

export const MainComponentCode = ({
  sessionInfo,
  gadParam,
  setSessionState,
  webValues,
}) => {
  const [code, setCode] = useState();
  const [codeSubmitted, setCodeSubmitted] = useState();
  const [codeOk, setCodeOk] = useState();
  useEffect(() => {
    if (sessionInfo.code) {
      setCode(sessionInfo.code);
    }
  }, [sessionInfo]);

  useEffect(() => {
    if (codeSubmitted && code === parseInt(codeSubmitted)) {
      setCodeOk(true);
    } else if (codeSubmitted && code !== codeSubmitted) {
      setCodeOk();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSubmitted]);

  const submitCode = () => {
    let codeT = "";
    for (let i = 1; i < 5; i++) {
      codeT += document.getElementById(`input-code-${i}`).value;
    }
    setCodeSubmitted(codeT);
  };

  const runGoogleTagManager = async () => {
    console.log("run google tag manager");
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
    };
    TagManager.initialize(tagManagerArgs);
  };

  const onClickCode = async () => {
    runGoogleTagManager();
    //update hasAccessed to true in session doc
    if (!sessionInfo.hasAccessed) {
      await updateHasAccessedDB(sessionInfo.id);
    }
    if (!sessionInfo.feedbackAt) {
      //user not comment yet
      setSessionState(3);
      mixpanel.track("watched preview", {
        gad: gadParam,
      });
    } else if (sessionInfo.feedbackAt && sessionInfo.requestImprovedVersion) {
      //user comment and request improved version
      setSessionState(7);
    } else if (sessionInfo.feedbackAt && !sessionInfo.requestImprovedVersion) {
      //user comment and downloaded file
      if (sessionInfo.userHasToPay && !sessionInfo.paid) {
        setSessionState(11);
      } else {
        setSessionState(6);
      }
    }
  };

  return (
    <div
      style={{
        height: "calc(100% - 95px)",
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
      }}
      className="flex flex-col w-full py-6 justify-center items-center h-full"
    >
      <div className="flex flex-col w-full px-10 py-6 h-full justify-center items-center">
        {" "}
        <div
          className="p-2 w-fit"
          style={{
            borderRadius: "90px",
            background: "var(--light-positive-background, #EDEEFE)",
          }}
        >
          <img alt="mail" src="/assets/mail.svg" className="w-12 h-12" />
        </div>
        <p className="subtitle-regular w-full text-center mt-4">
          {webValues?.strings?.codeTitle}
        </p>
        <p className="caption mt-2 text-center">
          {webValues?.strings?.codeSubtitle}
        </p>
        <div className="flex justify-center items-center my-6">
          <input
            id="input-code-1"
            onChange={(e) => {
              if (e.target.value !== "") {
                document.getElementById("input-code-2").focus();
              }
              submitCode();
            }}
            maxLength={1}
            type="text"
            className="text-center border border-[#3AB4EB] w-[31px] h-[48px] rounded-[8px] mx-1"
          />
          <input
            id="input-code-2"
            onChange={(e) => {
              if (e.target.value !== "") {
                document.getElementById("input-code-3").focus();
              }
              submitCode();
            }}
            maxLength={1}
            type="text"
            className="text-center border border-[#3AB4EB] w-[31px] h-[48px] rounded-[8px] mx-1"
          />
          <input
            id="input-code-3"
            onChange={(e) => {
              if (e.target.value !== "") {
                document.getElementById("input-code-4").focus();
              }
              submitCode();
            }}
            maxLength={1}
            type="text"
            className="text-center border border-[#3AB4EB] w-[31px] h-[48px] rounded-[8px] mx-1"
          />
          <input
            id="input-code-4"
            onChange={(e) => {
              if (e.target.value !== "") {
                document.getElementById("btn-submit-drawing-ready").focus();
              }
              submitCode();
            }}
            maxLength={1}
            type="text"
            className="text-center border border-[#3AB4EB] w-[31px] h-[48px] rounded-[8px] mx-1"
          />
        </div>
        <div className="flex flex-col items-center w-full">
          <Button
            id="btn-submit-drawing-ready"
            className="button-style-1 w-full mb-4"
            disabled={!codeOk}
            onClick={onClickCode}
            type="button"
          >
            {webValues?.strings?.codeButton}
          </Button>
        </div>
      </div>
    </div>
  );
};
