import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";

export const BackgroundDiv = ({
  backgroundsArray,
  setBgIndex,
  webValues,
}) => {
  const [images, setImages] = useState();

  useEffect(() => {
    if (backgroundsArray && backgroundsArray.length > 0) {
      let bg = [];
      backgroundsArray.map((item) => {
        return bg.push(item.url);
      });
      setImages(bg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundsArray]);

  return (
    images && (
      <div
        style={{
          width: "100%",
          zIndex: "-10",
          position: "absolute",
          height: "calc(100vh - 75px)",
        }}
      >
        <Swiper
          className="w-full h-full"
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: webValues?.delay?.background * 1000,
            // disableOnInteraction: false,
          }}
          onSlideChange={(swiper) => setBgIndex(swiper.realIndex)}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {images.map((i, key) => (
            <SwiperSlide key={key}>
              <img className="w-full h-full object-cover" src={i} alt={key} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  );
};
