import { Button, IconCheckmark } from "@veneer/core";
import mixpanel from "mixpanel-browser";
import { isMobile } from "react-device-detect";
import { getImgStorageDB } from "../lib/db";
import { saveAs } from "file-saver";
import { useState } from "react";

export const MainComponentIsDownloaded = ({
  setSessionState,
  setSessionId,
  setSessionInfo,
  sessionInfo,
  gadParam,
  navigate,
  webValues,
}) => {
  const [errorFile, setErrorFile] = useState();
  const resetSession = () => {
    setSessionState(0);
    setSessionId();
    setSessionInfo();
    if (gadParam && gadParam !== "none") {
      navigate(`/user?gad=${gadParam}`);
    } else {
      navigate(`/user`);
    }
  };

  const downloadDoc = async () => {
    setErrorFile();
    mixpanel.track("download", { gad: gadParam });
    let fileUrl = await getImgStorageDB("cadFiles", sessionInfo?.urlCad);
    if (fileUrl) {
      saveAs(fileUrl);
      setSessionState(6);
    } else {
      setErrorFile(true);
    }
  };
  return (
    <div
      style={{
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
        height: "calc(100% - 95px)",
      }}
      className="flex flex-col items-center justify-center py-6"
    >
      <div
        className="p-2"
        style={{
          borderRadius: "90px",
          background:
            "var(--light-positive-background, rgba(0, 131, 75, 0.10))",
        }}
      >
        <IconCheckmark color="colorGreen6" size={32} />
      </div>

      <p className="text-[24px] mt-3 text-center">
        {webValues?.strings?.drawingDownloadedTitle}
      </p>
      <p className="caption mt-4 text-center">
        {webValues?.strings?.drawingDownloadedSubtitle}
      </p>
      <div className="mt-8 w-full">
        <Button
          className="button-style-1 w-full"
          type="button"
          onClick={resetSession}
        >
          {webValues?.strings?.drawingDownloadedButtonAnother}
        </Button>
      </div>
      <div className="my-4 w-full">
        <Button
          className="button-style-1 w-full"
          type="button"
          onClick={downloadDoc}
        >
          {webValues?.strings?.drawingDownloadedButtonDownloadAgain}
        </Button>
      </div>
      {errorFile && (
        <div className="w-full text-left text-[red] text-sm">
          <p>{webValues?.strings?.drawingDownloadedErrorDownloadFileText}</p>
        </div>
      )}
    </div>
  );
};
