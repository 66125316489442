import { AccurateEmotisItem } from "./accurate-emotis-item";

export const AccurateEmotis = ({
  webValues,
  setUserResponse,
  userResponse,
}) => {
  return (
    <div className="w-full">
      <p className="text-[18px] mt-6">
        {webValues?.strings?.accurateEmotiTitle}
      </p>
      <div className="my-6">
        <AccurateEmotisItem
          setUserResponse={setUserResponse}
          number={1}
          value={webValues?.strings?.accurateTitle1}
          userResponse={userResponse}
        />
        <AccurateEmotisItem
          setUserResponse={setUserResponse}
          number={2}
          value={webValues?.strings?.accurateTitle2}
          userResponse={userResponse}
        />
        <AccurateEmotisItem
          setUserResponse={setUserResponse}
          number={3}
          value={webValues?.strings?.accurateTitle3}
          userResponse={userResponse}
        />
        <AccurateEmotisItem
          setUserResponse={setUserResponse}
          number={4}
          value={webValues?.strings?.accurateTitle4}
          userResponse={userResponse}
        />
        <AccurateEmotisItem
          setUserResponse={setUserResponse}
          number={5}
          value={webValues?.strings?.accurateTitle5}
          userResponse={userResponse}
        />
      </div>
    </div>
  );
};
