import { TextBox } from "@veneer/core";

export const MainComponentSendInvoice = ({
  name,
  setName,
  company,
  setCompany,
  vat,
  setVat,
  address,
  setAddress,
  city,
  setCity,
  zip,
  setZip,
  setErrorFormInvoice,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full mt-2">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="w-full my-2">
          <TextBox
            id="input-name-invoice"
            onChange={(e) => {
              setName(e);
              setErrorFormInvoice();
            }}
            required
            value={name}
            placeholder="Name and Surname"
          />
        </div>
        <div className="w-full my-2">
          <TextBox
            id="input-company-invoice"
            onChange={(e) => {
              setCompany(e);
              setErrorFormInvoice();
            }}
            required
            value={company}
            placeholder="Company Name"
          />
        </div>
        <div className="w-full my-2">
          <TextBox
            id="input-vat-invoice"
            onChange={(e) => {
              setVat(e);
              setErrorFormInvoice();
            }}
            required
            value={vat}
            placeholder="Vat Number"
          />
        </div>
        <div className="w-full my-2">
          <TextBox
            id="input-address-invoice"
            onChange={(e) => {
              setAddress(e);
              setErrorFormInvoice();
            }}
            required
            value={address}
            placeholder="Address"
          />
        </div>
        <div className="flex justify-between items-center w-full my-2">
          <div className="mr-1">
            <TextBox
              id="input-city-invoice"
              onChange={(e) => {
                setCity(e);
                setErrorFormInvoice();
              }}
              required
              value={city}
              placeholder="City"
            />
          </div>
          <div className="ml-1">
            <TextBox
              id="input-zip-invoice"
              onChange={(e) => {
                setZip(e);
                setErrorFormInvoice();
              }}
              required
              value={zip}
              placeholder="Zip Code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
