import { IconClock } from "@veneer/core";

export const MainComponentPaymentInProcess = ({ webValues }) => {
  return (
    <div className="flex flex-col items-center justify-center pt-8">
      <IconClock color="colorHpBlue6" size={48} />
      <p className="title-regular mt-3 text-center">
        {webValues?.strings?.paymentInProgressTitle}
      </p>
      <p className="caption mt-3 text-center">
        {webValues?.strings?.paymentInProgressSubtitle}
      </p>
    </div>
  );
};
