import { useEffect, useState } from "react";

export const NavbarButtonsInfo1 = ({ content }) => {
  const [contentArray, setContentArray] = useState();
  useEffect(() => {
    if (content) {
      setContentArray(content.split("/"));
    }
  }, [content]);
  return (
    <div>
      {contentArray?.map((item, key) => (
        <p key={key} className="body mt-6">
          {item}
        </p>
      ))}
    </div>
  );
};
