import { Modal } from "@veneer/core";
import { useEffect, useState } from "react";

export const PolicyModal = ({
  showPolicyModal,
  setShowPolicyModal,
  webValues,
}) => {
  const [contentArray, setContentArray] = useState();
  useEffect(() => {
    if (webValues?.strings?.policyData) {
      setContentArray(webValues?.strings?.policyData.split("/"));
    }
  }, [webValues]);
  return (
    <Modal
      align="start"
      id="modal-data-policy"
      show={showPolicyModal}
      title={webValues?.strings?.policyDataTitle}
      closeButton
      onClose={() => setShowPolicyModal()}
    >
      <div className="flex flex-col">
        {contentArray?.map((item, key) => (
          <div className="w-full">
            <p className="body mt-2.5">
              {item}
              {key + 1 === contentArray?.length && (
                //mailto
                <a
                  style={{ textDecoration: "none" }}
                  href={`mailto:${webValues?.strings?.policyDataEmail}`}
                  className="text-primaryBlue"
                >
                  {" "}
                  {webValues?.strings?.policyDataEmail}
                </a>
              )}
            </p>
          </div>
        ))}
        {webValues?.strings?.policyDataExternalText &&
          webValues?.strings?.policyDataExternalText !== "" && (
            <p className="body mt-2.5">
              {webValues?.strings?.policyDataExternalText}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                href={webValues?.strings?.policyDataExternalLinkHref}
                className="text-primaryBlue"
              >
                {webValues?.strings?.policyDataExternalLink}
              </a>
              .
            </p>
          )}
      </div>
    </Modal>
  );
};
