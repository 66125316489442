import { isMobile } from "react-device-detect";
import { MainComponentDrawingProgress } from "./main-component-drawing-progress";
import { MainComponentDrawingReady } from "./main-component-drawing-ready";
import { MainComponentDrawingReadyGetImproved } from "./main-component-drawing-ready-get-improved";
import { MainComponentDrawingReadyStep2 } from "./main-component-drawing-ready-step-2";
import { MainComponentFileUploaded } from "./main-component-file-uploaded";
import { MainComponentIsDownloaded } from "./main-component-is-downloaded";
import { MainComponentTopStatus } from "./main-component-top-status";
import { MainComponentUploadForm } from "./main-component-upload-form";
import { MainComponentCode } from "./main-component-code";
import { ErrorModal } from "./error-modal";
import { MainComponentPayAndDonwload } from "./main-component-pay-and-download";
import { MainComponentIsPaid } from "./main-component-is-paid";
import { MainComponentSendInvoice } from "./main-component-send-invoice";

export const MainComponent = ({
  isTest,
  setShowPolicyModal,
  sessionInfo,
  sessionId,
  setSessionId,
  navigate,
  sessionState,
  setSessionState,
  webValuesV3,
  gadParam,
  setNewSessionDataTypeB,
  setSessionInfo,
  analyzedProgress,
  setAnalyzedProgress,
  cleanedProgress,
  setCleanedProgress,
  recognizingProgress,
  setRecognizingProgress,
  now,
  setNow,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  percentage,
  setPercentage,
  limit,
  setIsDownloadFlow,
  isDownloadFlow,
  userHasToPay,
  invoice,
  setInvoice,
}) => {
  return (
    <div
      style={{
        border: isMobile ? "" : "1px solid #DBDBDB",
        // width: isMobile ? "100%" : "33.33%",
        // marginBottom: isMobile ? "36px" : "96px",
      }}
      className="overflow-y-auto overflow-x-hidden w-full min-[1150px]:w-1/3 bg-white flex flex-wrap justify-between flex-col h-full"
    >
      {sessionState !== 7 && sessionState !== 9 && sessionState !== 10 && (
        <MainComponentTopStatus
          webValues={webValuesV3}
          sessionState={sessionState}
          sessionInfo={sessionInfo}
        />
      )}
      {sessionState === 0 && (
        <MainComponentUploadForm
          navigate={navigate}
          setSessionId={setSessionId}
          setSessionState={setSessionState}
          setShowPolicyModal={setShowPolicyModal}
          sessionId={sessionId}
          sessionInfo={sessionInfo}
          setSessionInfo={setSessionInfo}
          gadParam={gadParam}
          isTest={isTest}
          setNewSessionDataTypeB={setNewSessionDataTypeB}
          webValues={webValuesV3}
          userHasToPay={userHasToPay}
        />
      )}
      {sessionState === 1 && (
        <MainComponentFileUploaded webValues={webValuesV3} />
      )}
      {sessionState === 2 && (
        <MainComponentDrawingProgress
          sessionInfo={sessionInfo}
          sessionState={sessionState}
          webValues={webValuesV3}
          setSessionState={setSessionState}
          analyzedProgress={analyzedProgress}
          setAnalyzedProgress={setAnalyzedProgress}
          cleanedProgress={cleanedProgress}
          setCleanedProgress={setCleanedProgress}
          recognizingProgress={recognizingProgress}
          setRecognizingProgress={setRecognizingProgress}
          now={now}
          setNow={setNow}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          percentage={percentage}
          setPercentage={setPercentage}
          limit={limit}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          navigate={navigate}
          gadParam={gadParam}
        />
      )}
      {sessionState === 3 && (
        <MainComponentDrawingReady
          sessionInfo={sessionInfo}
          setSessionState={setSessionState}
          gadParam={gadParam}
          sessionId={sessionId}
          setSessionId={setSessionId}
          navigate={navigate}
          setSessionInfo={setSessionInfo}
          webValues={webValuesV3}
          setIsDownloadFlow={setIsDownloadFlow}
        />
      )}
      {(sessionState === 4 || sessionState === 5) && (
        <MainComponentDrawingReadyStep2
          sessionInfo={sessionInfo}
          setSessionState={setSessionState}
          gadParam={gadParam}
          sessionState={sessionState}
          webValues={webValuesV3}
          isDownloadFlow={isDownloadFlow}
        />
      )}
      {sessionState === 6 && (
        <MainComponentIsDownloaded
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          setSessionState={setSessionState}
          gadParam={gadParam}
          navigate={navigate}
          sessionInfo={sessionInfo}
          webValues={webValuesV3}
        />
      )}
      {sessionState === 7 && (
        <MainComponentDrawingReadyGetImproved
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          setSessionState={setSessionState}
          gadParam={gadParam}
          navigate={navigate}
          webValues={webValuesV3}
          setIsDownloadFlow={setIsDownloadFlow}
          isDownloadFlow={isDownloadFlow}
        />
      )}
      {sessionState === 8 && (
        <MainComponentCode
          sessionInfo={sessionInfo}
          setSessionState={setSessionState}
          gadParam={gadParam}
          webValues={webValuesV3}
        />
      )}
      {sessionState === 9 && (
        <ErrorModal
          setSessionState={setSessionState}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          navigate={navigate}
          gadParam={gadParam}
          text={webValuesV3?.strings?.technicalError}
          title={webValuesV3?.strings?.technicalErrorTitle}
          buttonText={webValuesV3?.strings?.technicalErrorButton}
        />
      )}
      {sessionState === 10 && (
        <ErrorModal
          setSessionState={setSessionState}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          navigate={navigate}
          gadParam={gadParam}
          text={webValuesV3?.strings?.outOfWorkingHours}
          title={webValuesV3?.strings?.outOfWorkingHoursTitle}
          buttonText={webValuesV3?.strings?.outOfWorkingHoursButton}
        />
      )}
      
      {sessionState === 11 && (
        <MainComponentPayAndDonwload
          sessionId={sessionId}
          setSessionId={setSessionId}
          sessionInfo={sessionInfo}
          sessionState={sessionState}
          setSessionState={setSessionState}
          gadParam={gadParam}
          invoice={invoice}
          setInvoice={setInvoice}
          isTest={isTest}
          navigate={navigate}
          isMobile={isMobile}
          webValues={webValuesV3}
          setSessionInfo={setSessionInfo}
        />
      )}
      {sessionState === 12 && (
        <MainComponentIsPaid
          sessionInfo={sessionInfo}
          setSessionState={setSessionState}
          gadParam={gadParam}
          invoice={invoice}
          isMobile={isMobile}
          webValues={webValuesV3}
        />
      )}
      {sessionState === 13 && (
        <MainComponentSendInvoice
          sessionInfo={sessionInfo}
          setSessionState={setSessionState}
          isMobile={isMobile}
        />
      )}
    </div>
  );
};
