import { Button } from "@veneer/core";
import { useState } from "react";
import mixpanel from "mixpanel-browser";
import { getImgStorageDB } from "../lib/db";
import { saveAs } from "file-saver";
import { isMobile } from "react-device-detect";
import { updateInStep2DB } from "../lib/db";
import { AccurateEmotis } from "./accurate-emotis";
import { ImprovedVersion } from "./improved-version";

export const MainComponentDrawingReadyStep2 = ({
  sessionInfo,
  sessionState,
  setSessionState,
  webValues,
  gadParam,
  isDownloadFlow,
}) => {
  const [userDownloadResponse, setUserDownloadResponse] = useState([]);
  const [userDownloadComment, setUserDownloadComment] = useState();
  const [userImprovedResponse, setUserImprovedResponse] = useState([]);
  const [userImprovedComment, setUserImprovedComment] = useState();
  const [errorFile, setErrorFile] = useState();
  const [error, setError] = useState();
  const onChangeTextArea = (e) => {
    if (isDownloadFlow && sessionState === 4)
      setUserDownloadComment(e.target.value);
    if (!isDownloadFlow || (isDownloadFlow && sessionState === 5))
      setUserImprovedComment(e.target.value);
  };
  const sendResponse = async () => {
    if (
      ((userDownloadComment === "" || !userDownloadComment) &&
        isDownloadFlow &&
        !sessionInfo.userHasToPay) ||
      ((userImprovedComment === "" || !userImprovedComment) &&
        !isDownloadFlow &&
        !sessionInfo.userHasToPay)
    ) {
      setError(true);
      return;
    }
    if (!sessionInfo.userHasToPay) {
      if (isDownloadFlow && sessionState === 4) {
        setSessionState(5);
      } else if (isDownloadFlow && sessionState === 5) {
        downloadDoc();
        await updateInStep2DB(
          sessionInfo.id,
          userDownloadResponse,
          userDownloadComment,
          userImprovedResponse,
          userImprovedComment,
          false
        );
      } else if (!isDownloadFlow) {
        await updateInStep2DB(
          sessionInfo.id,
          [],
          "",
          userImprovedResponse,
          userImprovedComment,
          true
        );
        setSessionState(7);
      }
    } else {
      await updateInStep2DB(
        sessionInfo.id,
        userDownloadResponse,
        userDownloadComment,
        userImprovedResponse,
        userImprovedComment,
        isDownloadFlow ? false : true
      );
      if (!isDownloadFlow) setSessionState(7);
      if (isDownloadFlow) setSessionState(11);
    }

    // await updateInStep2DB(
    //   sessionInfo.id,
    //   userResponse,
    //   userComment,
    //   sessionState === 5 ? true : false
    // );
  };

  const downloadDoc = async () => {
    setErrorFile();
    mixpanel.track("download", { gad: gadParam });
    let fileUrl = await getImgStorageDB("cadFiles", sessionInfo?.urlCad);
    if (fileUrl) {
      saveAs(fileUrl);
      setSessionState(6);
    } else {
      setErrorFile(true);
    }
  };
  return (
    <div
      style={{
        height: "calc(100% - 95px)",
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
      }}
      className="flex flex-col w-full py-6 justify-center items-center h-full"
    >
      <div className="flex flex-col w-full py-6 h-full">
        <p className="text-[24px] w-full">
          {sessionState === 4
            ? webValues?.strings?.accurateTitle
            : sessionState === 5 && isDownloadFlow
            ? webValues?.strings?.improvedDownloadVersionTitle
            : webValues?.strings?.improvedVersionTitle}
        </p>

        {sessionState === 4 && (
          <p className="text-[16px] mt-6">
            {webValues?.strings?.accurateSubtitleDownload}
          </p>
        )}
        {sessionState === 4 && (
          <AccurateEmotis
            webValues={webValues}
            setUserResponse={setUserDownloadResponse}
            userResponse={userDownloadResponse}
          />
        )}
        {sessionState === 5 && (
          <ImprovedVersion
            webValues={webValues}
            setUserResponse={setUserImprovedResponse}
            userResponse={userImprovedResponse}
            isDownloadFlow={isDownloadFlow}
          />
        )}
        <p className="caption text-[16px]">
          {sessionState === 4
            ? webValues?.strings?.accurateTextAreaTitle
            : sessionState === 5 && isDownloadFlow
            ? webValues?.strings?.improvedDownloadVersionTextAreaTitle
            : webValues?.strings?.improvedVersionTextAreaTitle}
        </p>
        {((isDownloadFlow && sessionState === 4) ||
          (!isDownloadFlow && sessionState === 5)) && (
          <div className="h-full">
            <textarea
              onChange={(e) => {
                onChangeTextArea(e);
                setError(false);
              }}
              style={{ resize: "none", border: error ? "1px solid red" : "" }}
              placeholder={
                sessionState === 4
                  ? webValues?.strings?.accurateTextAreaPlaceholder
                  : sessionState === 5 && isDownloadFlow
                  ? webValues?.strings
                      ?.improvedDownloadVersionTextAreaPlaceholder
                  : webValues?.strings?.improvedVersionTextAreaPlaceholder
              }
              className="w-full h-full border border-[#DBDBDB] rounded-[12px] mt-2 p-2"
            ></textarea>
          </div>
        )}
        {isDownloadFlow && sessionState === 5 && (
          <div className="h-full">
            <textarea
              onChange={(e) => {
                onChangeTextArea(e);
                setError(false);
              }}
              style={{ resize: "none", border: error ? "1px solid red" : "" }}
              placeholder={
                sessionState === 4
                  ? webValues?.strings?.accurateTextAreaPlaceholder
                  : sessionState === 5 && isDownloadFlow
                  ? webValues?.strings
                      ?.improvedDownloadVersionTextAreaPlaceholder
                  : webValues?.strings?.improvedVersionTextAreaPlaceholder
              }
              className="w-full h-full border border-[#DBDBDB] rounded-[12px] mt-2 p-2"
            ></textarea>
          </div>
        )}
        <div className="pb-6 mt-6 w-full">
          <Button
            type="button"
            disabled={
              ((userDownloadComment === "" ||
                !userDownloadComment ||
                userDownloadResponse.length === 0) &&
                sessionState === 4 &&
                !sessionInfo.userHasToPay) ||
              ((userImprovedComment === "" ||
                !userImprovedComment ||
                userImprovedResponse.length === 0) &&
                sessionState === 5 &&
                !isDownloadFlow &&
                !sessionInfo.userHasToPay)
            }
            className="w-full  button-style-1"
            onClick={() => sendResponse()}
            appearance="primary"
          >
            {sessionState === 4 && isDownloadFlow && !sessionInfo.userHasToPay
              ? webValues?.strings?.accurateButtonNextText
              : sessionState === 4 && isDownloadFlow && sessionInfo.userHasToPay
              ? webValues?.strings?.accurateButtonNextTextUserHasToPay
              : sessionState === 5 && isDownloadFlow
              ? webValues?.strings?.accurateButtonText
              : webValues?.strings?.improvedVersionButtonText}
          </Button>
        </div>
        {errorFile && (
          <div className="w-full text-left text-[red] text-sm mb-6 mt-4">
            <p>{webValues?.strings?.drawingDownloadedErrorDownloadFileText}</p>
          </div>
        )}
      </div>
    </div>
  );
};
