import {
  Button,
  IconResetPassword,
  IconRotateClockwise,
  IconZoomIn,
  IconZoomOut,
} from "@veneer/core";
import { useEffect, useState } from "react";
import { getImgStorageDB } from "../lib/db";
// import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";

const Preview = ({ session }) => {
  const [isLoading, setIsLoading] = useState();
  const [urn, setUrn] = useState();
  const [token, setToken] = useState();
  const [imageStorage, setImageStorage] = useState();
  const [rotate, setRotate] = useState(0);

  const Controls = ({ setRotate, rotate }) => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <div className="w-full flex justify-end items-center">
        <button className="mx-2" title="Zoom in" onClick={() => zoomIn()}>
          <IconZoomIn size={36} />
        </button>
        <button className="mx-2" title="Zoom out" onClick={() => zoomOut()}>
          <IconZoomOut size={36} />
        </button>
        <button
          className="mx-2"
          title="Rotate"
          onClick={() => {
            if (rotate === 270) {
              setRotate(0);
            } else {
              setRotate(rotate + 90);
            }
            document.getElementById("preview-image").style.transform =
              "rotate(" + (rotate + 90) + "deg)";
          }}
        >
          <IconRotateClockwise size={36} />
        </button>
        <button
          className="mx-2"
          title="Reset"
          onClick={() => {
            resetTransform();
            setRotate(0);
            document.getElementById("preview-image").style.transform =
              "rotate(" + 0 + "deg)";
          }}
        >
          <IconResetPassword size={36} />
        </button>
      </div>
    );
  };
  useEffect(() => {
    if (session) {
      getPreviewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const getPreviewData = async () => {
    setIsLoading(true);
    if (!session.urlSVG) {
      const url = process.env.REACT_APP_URL;
      const urlPreview = `${url}/createViewer?fileID=${session.id}`;
      const response = await fetch(urlPreview, {
        method: "GET",
        // headers: { "Content-Type": "application/json" },
      });
      if (response.ok) {
        let data = await response.json();
        setUrn(data?.urn);
        setToken(data?.token);
        setIsLoading();
      } else {
        setIsLoading();
        return "Error";
      }
    } else {
      setImageStorage(await getImgStorageDB("svgFiles", session.urlSVG));
      setIsLoading();
    }
  };
  return (
    <div className="h-full p-2">
      {!isLoading && imageStorage && (
        <div className="w-full h-full flex flex-col">
          {/* <Zoom>
            <img
              alt="Preview"
              src={imageStorage}
              width="100%"
              height={"100%"}
            />
          </Zoom> */}

          <TransformWrapper>
            <Controls setRotate={setRotate} rotate={rotate} />
            <TransformComponent>
              <img
                style={{ margin: 0, padding: 0, width: "100%", height: "100%" }}
                id="preview-image"
                src={imageStorage}
                alt="Preview"
                width="100%"
                height="100%"
              />
            </TransformComponent>
          </TransformWrapper>
        </div>
      )}
      {token && urn && !isLoading && (
        <iframe
          title="Preview Plan2Cad"
          style={{ width: "100%", height: "100%" }}
          src={`https://plan-to-cad-plan2cad-cad-viewer.vercel.app/?token=${token}&urn=${urn}`}
        />
      )}
      {isLoading && (
        <div className="w-full flex h-full justify-center items-center bg-white">
          <Button appearance="ghost" loading></Button>
        </div>
      )}
      {/* <PreviewModal sessionId={sessionId} sessionInfo={sessionInfo}/> */}
    </div>
  );
};
export default Preview;
