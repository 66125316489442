import {
  IconCashDrawer,
  IconCheckmark,
  IconDownload,
  IconEnhance,
  IconStar,
  IconUpload,
  Stepper,
} from "@veneer/core";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export const MainComponentTopStatus = ({
  sessionState,
  webValues,
  sessionInfo,
}) => {
  const [steps, setSteps] = useState();
  useEffect(() => {
    let downloadLabel = "Download";
    if (
      webValues?.strings?.step3Title &&
      !sessionInfo?.userHasToPay &&
      !webValues?.userHasToPay
    ) {
      downloadLabel = webValues?.strings?.step3Title;
    }
    if (sessionInfo?.userHasToPay) {
      downloadLabel = "Pay & Download";
    }
    if (webValues?.userHasToPay && !sessionInfo) {
      downloadLabel = "Pay & Download";
    }

    if (sessionState === 0) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "current",
          step: <IconUpload />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "incomplete",
          step: <IconStar />,
        },
        {
          label: downloadLabel,
          status: "incomplete",
          step: <IconDownload />,
        },
      ]);
    }
    if (sessionState >= 1) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "current",
          step: <IconUpload color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "incomplete",
          step: <IconStar />,
        },
        {
          label: downloadLabel,
          status: "incomplete",
          step: <IconDownload />,
        },
      ]);
    }
    if (sessionState > 2) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "current",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "incomplete",
          step: <IconStar />,
        },
        {
          label: downloadLabel,
          status: "incomplete",
          step: <IconDownload />,
        },
      ]);
    }
    if ((sessionState >= 3 && sessionState < 4) || sessionState === 8) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "current",
          step: <IconStar />,
        },
        {
          label: downloadLabel,
          status: "incomplete",
          step: <IconDownload />,
        },
      ]);
      return;
    }
    if (sessionState === 4) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: downloadLabel,
          status: "current",
          step: <IconDownload />,
        },
      ]);
    }
    if (sessionState === 5) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step3Title
            ? webValues?.strings?.step3ImprovedVersionTitle
            : "Enhance",
          status: "current",
          step: <IconEnhance />,
        },
      ]);
    }
    if (sessionState === 11) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: "Pay & Download",
          status: "current",
          step: <IconCashDrawer />,
        },
      ]);
      return;
    }
    if (sessionState >= 6) {
      setSteps([
        {
          label: webValues?.strings?.step1Title
            ? webValues?.strings?.step1Title
            : "Upload",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: webValues?.strings?.step2Title
            ? webValues?.strings?.step2Title
            : "Review",
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
        {
          label: downloadLabel,
          status: "complete",
          step: <IconCheckmark color="colorWhite" />,
        },
      ]);
    }
  }, [sessionState, webValues, sessionInfo]);

  return (
    <div
      className="flex items-center justify-center w-full pt-4 pb-1"
      style={{
        background: !isMobile ? "rgba(173, 173, 173, 0.15)" : "white",
        borderBottom: !isMobile ? "1px dashed #404040" : "0",
      }}
    >
      <Stepper steps={steps} />
    </div>
  );
};
