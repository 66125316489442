/* eslint-disable react-hooks/exhaustive-deps */
import { getImgStorageDB } from "../lib/db";
import { saveAs } from "file-saver";
import { Button, IconCheckmarkCircle } from "@veneer/core";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";

export const MainComponentIsPaid = ({
  sessionInfo,
  setSessionState,
  gadParam,
  invoice,
  projectType,
  isMobile,
  webValues,
}) => {
  const [errorFile, setErrorFile] = useState();
  const downloadDoc = async () => {
    setErrorFile();
    mixpanel.track("download", { gad: gadParam, projectType: projectType });
    let fileUrl = await getImgStorageDB("cadFiles", sessionInfo?.urlCad);
    if (fileUrl) {
      saveAs(fileUrl);
      setSessionState(6);
    } else {
      setErrorFile(true);
    }
  };

  useEffect(() => {
    if (projectType === "B") {
      setSessionState(2);
      mixpanel.track("paid, enter while waiting", {
        gad: gadParam,
        projectType: projectType,
      });
    }
  }, [projectType]);

  return (
    <div
      style={{
        height: "calc(100% - 95px)",
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
      }}
      className="flex flex-col w-full py-6 justify-center items-center h-full"
    >
      <IconCheckmarkCircle color="colorHpBlue6" size={48} />

      <p className="mt-6 text-center text-[36px]">
        {webValues?.strings?.paymentSuccessfulTitle}
      </p>

      <p className="caption mt-6 text-center">
        {webValues?.strings?.paymentSuccessfulSubtitle}
      </p>

      {invoice && (
        <div>
          <p className="caption mt-6 text-center">
            {webValues?.strings?.paymentSuccessfulInvoiceText}
          </p>
        </div>
      )}

      {errorFile && (
        <span className="text-center px-5 text-red-500 text-sm my-4">
          {webValues?.strings?.drawingDownloadedErrorDownloadFileText}
        </span>
      )}

      <div className="mt-6 mb-6">
        <Button className="button-style-1" onClick={downloadDoc} type="button">
          {webValues?.strings?.paymentSuccessfulButton}
        </Button>
      </div>
    </div>
  );
};
