import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { Button, Checkbox, IconCashDrawer } from "@veneer/core";
import mixpanel from "mixpanel-browser";
import { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  sendNewPaymentEmailDB,
  updateHasInvoiceDB,
  updateInvoiceNumberDB,
  updateIsPaidSessionTypeADB,
} from "../lib/db";
import { getDateInvoice } from "../lib/utils";
import { MainComponentPaymentInProcess } from "./main-component-payment-in-process";
import { MainComponentSendInvoice } from "./main-component-send-invoice";
import { ErrorModal } from "./error-modal";

export const MainComponentStripeForm = ({
  isTest,
  sessionId,
  setSessionId,
  sessionInfo,
  setSessionInfo,
  setSessionState,
  gadParam,
  invoice,
  setInvoice,
  amount,
  currency,
  projectType,
  newSessionDataTypeB,
  navigate,
  invoiceNumber,
  getInvoiceNumber,
  isMobile,
  webValues,
}) => {
  const myRef = useRef();
  const myRefStart = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [vat, setVat] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [errorFormInvoice, setErrorFormInvoice] = useState();
  const [isLoading, setIsLoading] = useState();
  const [showModalError, setShowModalError] = useState();

  useEffect(() => {
    if (invoice) {
      // document
      //   .getElementById("send-invoice-div")
      //   ?.scrollIntoView({ behavior: "smooth" });
      myRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      myRefStart.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [invoice]);

  useEffect(() => {
    if (sessionInfo) {
      setEmail(sessionInfo.userEmail);
    } else {
      if (projectType === "B") {
        setEmail(newSessionDataTypeB?.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo]);

  const updateInvoiceNumber = async () => {
    await updateInvoiceNumberDB(invoiceNumber);
  };

  const sendInvoiceData = async (sessionIdParam) => {
    let vat_percentage;
    let vat_amount;
    let total;
    if (currency === "gbp") {
      vat_percentage = 0;
    } else {
      vat_percentage = 21;
    }
    vat_amount = (vat_percentage / 100) * amount;
    total = amount + vat_amount;
    const url = process.env.REACT_APP_URL;
    let date = getDateInvoice();
    const urlInvoice = `${url}/sendInvoice`;
    const response = await fetch(urlInvoice, {
      method: "POST",
      body: JSON.stringify({
        email,
        name,
        company,
        address,
        city,
        zip,
        amount,
        currency,
        total: total,
        vat_number: vat,
        vat_percentage: vat_percentage,
        vat_amount: vat_amount,
        session: sessionIdParam,
        date,
        invoiceNumber,
      }),
    });
    if (response.ok) {
      // setIsLoading();
      // setSessionState(5);
      await updateHasInvoiceDB(sessionIdParam);
      await updateInvoiceNumber();
      await getInvoiceNumber();
      console.log("Invoice ok");
    } else {
      console.log("Error to send invoice");
    }
  };

  const handleSubmit = async () => {
    console.log("submit stripe");
    if (
      invoice &&
      (!email ||
        email === "" ||
        !name ||
        name === "" ||
        !company ||
        company === "" ||
        !vat ||
        vat === "" ||
        !address ||
        address === "" ||
        !city ||
        city === "" ||
        !zip ||
        zip === "")
    ) {
      console.log("error in invoice form");
      if (!name || name === "") {
        document.getElementById("input-name-invoice").focus();
      } else if (!company || company === "") {
        document.getElementById("input-company-invoice").focus();
      } else if (!vat || vat === "") {
        document.getElementById("input-vat-invoice").focus();
      } else if (!address || address === "") {
        document.getElementById("input-address-invoice").focus();
      } else if (!city || city === "") {
        document.getElementById("input-city-invoice").focus();
      } else if (!zip || zip === "") {
        document.getElementById("input-zip-invoice").focus();
      }

      setErrorFormInvoice(true);
      return;
    } else {
      console.log("ok invoice form");
      setErrorFormInvoice();
    }

    mixpanel.track("pay", { gad: gadParam, projectType: projectType });

    if (!stripe || !elements) {
      return;
    } else if (stripe && elements && !errorFormInvoice) {
      setIsLoading(true);
      let url = window.location.origin;
      let returnUrl = url + "/user?sessionId=" + sessionId;
      await stripe
        .confirmPayment({
          elements,
          redirect: "if_required",
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: returnUrl,
          },
        })
        .then(async function (result) {
          if (result.error) {
            console.log("Error", result.error.message);
            setShowModalError(true);
            setIsLoading();
          } else {
            console.log("Payment success", result.paymentIntent);

            await updateIsPaidSessionTypeADB(sessionId);
            if (!isTest) {
              await sendNewPaymentEmailDB();
              if (invoice) {
                await sendInvoiceData(sessionId);
              }
            }

            setSessionState(12);
            setIsLoading();

            // if (!invoice) {
            //   setSessionState(5);
            //   setIsLoading();
            // }
            // else if (invoice) {
            //   setSessionState(7);
            //   setIsLoading();
            // }

            // navigate(`/user?sessionId=${sessionId}`);
          }
        });
    }
  };

  return (
    <div
      ref={myRefStart}
      style={{
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
        height: "100%",
      }}
      className="flex flex-col items-center justify-center"
    >
      <form
        onSubmit={handleSubmit}
        className="no-scrollbar overflow-y-auto overflow-x-hidden"
        style={{
          height: isLoading
            ? "0"
            : !invoice && !isLoading
            ? "calc(100%)"
            : "460px",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <IconCashDrawer color="colorHpBlue6" size={60} />
          <p className="subtitle-regular text-center mt-2">
            {webValues?.strings?.paymentStripeTitle} {currency === "gbp" && "£"}{" "}
            {currency === "usd" && "$"} {amount} {currency === "eur" && "€"}
          </p>
          <p className="text-center caption-small mt-2 mb-4">
            {webValues?.strings?.paymentStripeSubtitle}
          </p>
        </div>
        <PaymentElement />

        {!isLoading && (
          <div ref={myRef} className="w-full mt-6 flex items-center">
            <Checkbox
              label={webValues?.strings?.paymentInvoiceCheckboxText}
              id="input-invoice"
              onChange={() => {
                setInvoice(!invoice);
              }}
              value={invoice}
              checked={invoice}
            />
          </div>
        )}
        {invoice && !isLoading && (
          <MainComponentSendInvoice
            errorFormInvoice={errorFormInvoice}
            setErrorFormInvoice={setErrorFormInvoice}
            name={name}
            setName={setName}
            company={company}
            setCompany={setCompany}
            vat={vat}
            setVat={setVat}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            zip={zip}
            setZip={setZip}
          />
        )}
        {errorFormInvoice && (
          <p className="text-sm text-red-500 mt-2">
            Please, complete the invoice fields
          </p>
        )}
        {!isLoading && (
          <div className="mt-6 mb-4">
            <Button
              onClick={handleSubmit}
              className="button-style-1"
              expanded
              type="button"
            >
              {webValues?.strings?.paymentStripeButton}
            </Button>
          </div>
        )}
      </form>
      {isLoading && <MainComponentPaymentInProcess webValues={webValues} />}
      {showModalError && (
        <ErrorModal
          setSessionState={setSessionState}
          setSessionId={setSessionId}
          setSessionInfo={setSessionInfo}
          navigate={navigate}
          gadParam={gadParam}
          text={"Error in payment process, please try again later."}
          title={"Error"}
          buttonText={"OK"}
          setShowModalError={setShowModalError}
        />
      )}
    </div>
  );
};
