import { Button } from "@veneer/core";
import { isMobile } from "react-device-detect";

export const MainComponentDrawingReadyGetImproved = ({
  setSessionState,
  gadParam,
  navigate,
  setSessionId,
  setSessionInfo,
  webValues,
}) => {
  const resetSession = () => {
    setSessionState(0);
    setSessionId();
    setSessionInfo();
    if (gadParam && gadParam !== "none") {
      navigate(`/user?gad=${gadParam}`);
    } else {
      navigate(`/user`);
    }
  };

  return (
    <div
      style={{
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
        height: "calc(100% - 95px)",
      }}
      className="flex flex-col w-full py-6 justify-center items-center h-full"
    >
      <div
        className="p-2 w-fit"
        style={{
          borderRadius: "90px",
          background: "var(--light-positive-background, #EDEEFE)",
        }}
      >
        <img alt="mail" src="/assets/ok.svg" className="w-12 h-12" />
      </div>
      <p className="text-[24px] text-center mt-5">
        {webValues?.strings?.drawingReadyGetImprovedVersionTitle}
      </p>
      <p className="caption mt-6 text-center">
        {webValues?.strings?.drawingReadyGetImprovedVersionSubtitle}
      </p>
      <p className="caption text-center">
        {webValues?.strings?.drawingReadyGetImprovedVersionSubtitle2}
      </p>
      <div className="pb-6 mt-6 w-full">
        <Button
          type="button"
          className="w-full button-style-1"
          onClick={() => resetSession()}
          appearance="primary"
        >
          {webValues?.strings?.drawingReadyGetImprovedVersionButton}
        </Button>
      </div>
    </div>
  );
};
