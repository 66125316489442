import {
  IconCheckmarkCircle,
  IconDocumentScanCloud,
  ProgressIndicator,
} from "@veneer/core";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

export const MainComponentDrawingProgress = ({
  sessionInfo,
  webValues,
  analyzedProgress,
  setAnalyzedProgress,
  cleanedProgress,
  setCleanedProgress,
  recognizingProgress,
  setRecognizingProgress,
  now,
  setNow,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  percentage,
  setPercentage,
  limit,
}) => {
  useEffect(() => {
    if (sessionInfo) {
      if (!sessionInfo.expectedDeadline) {
        let createdAt = sessionInfo.createdAt.seconds;
        const limitTime = (createdAt + 3600 * 4) * 1000;
        createdAt = createdAt * 1000;

        if (now > limitTime) {
          setAnalyzedProgress(100);
        } else {
          let per = Math.round(
            ((now - createdAt) / (limitTime - createdAt)) * 100
          );
          setAnalyzedProgress(per);
        }
        setCleanedProgress(0);
        setRecognizingProgress(0);
        setTimeout(() => {
          setNow(new Date().getTime());
        }, 10000);
      } else {
        setAnalyzedProgress(100);
        setStartDate(sessionInfo?.startedWorkingAt?.seconds * 1000);
        setEndDate(sessionInfo?.expectedDeadline?.seconds * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo, now]);

  useEffect(() => {
    if (endDate && startDate) {
      setPercentage(
        Math.round(((now - startDate) / (endDate - startDate)) * 100)
      );
      setTimeout(() => {
        setNow(new Date().getTime());
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, now]);

  useEffect(() => {
    if (parseInt(percentage) >= 0) {
      if (percentage <= limit) {
        setCleanedProgress(Math.round((percentage * 100) / limit));
        setRecognizingProgress(0);
      } else {
        setCleanedProgress(100);
        let percentageT = percentage - limit;
        setRecognizingProgress(
          Math.round((percentageT * 100) / limit) < 100
            ? Math.round((percentageT * 100) / limit)
            : 100
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage, now]);

  //useEffect with recognizingProgress
  // useEffect(() => {
  //   if (parseInt(recognizingProgress) >= 0) {
  //     if (recognizingProgress === 100) {
  //       setSessionState(3);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [recognizingProgress, now]);
  return (
    <div
      style={{
        paddingLeft: isMobile ? "24px" : "48px",
        paddingRight: isMobile ? "24px" : "48px",
        height: "calc(100% - 95px)",
      }}
      className="flex flex-col justify-center w-full"
    >
      <div className="bg-[#EDEEFE] rounded-[50%] w-fit p-4">
        <IconDocumentScanCloud color="colorHpBlue6" size={32} />
      </div>
      <p className="subtitle-regular mt-2 ">
        {webValues?.strings?.drawingProgressTitle}
      </p>
      <p className="caption mt-4">
        {webValues?.strings?.drawingProgressSubtitle}
      </p>

      <div
        className="flex justify-between items-center mt-6 pt-10 pb-3 px-2 w-full"
        style={{ borderTop: "1px solid #DBDBDB" }}
      >
        <p className="text-[16px] font-bold">Status:</p>
      </div>
      <div className="flex flex-col items-center mb-4 px-2 w-full">
        <div className="flex w-full justify-between items-center my-3">
          <p className="caption w-full">{webValues?.strings?.bar1Title}</p>
          <div
            className="w-full my-1 ml-2 mr-4"
            style={{ borderRadius: "2px", background: "#DBDBDB" }}
          >
            <ProgressIndicator
              appearance="linear"
              color="colorHpBlue6"
              behavior="determinate"
              value={analyzedProgress}
            />
          </div>

          <div className="flex items-center w-[69px]">
            <p className="caption mr-2 w-[37px] text-right">
              {analyzedProgress >= 0 ? analyzedProgress : 0}%
            </p>
            {analyzedProgress >= 100 ? (
              <IconCheckmarkCircle color="colorGreen6" filled />
            ) : (
              <div className="w-6 h-6"></div>
            )}
          </div>
        </div>
        {percentage && percentage > 0 && cleanedProgress > 0 ? (
          <div className="flex w-full justify-between items-center">
            <p className="caption w-full">{webValues?.strings?.bar2Title}</p>
            <div
              className="w-full my-1 ml-2 mr-4"
              style={{ borderRadius: "2px", background: "#DBDBDB" }}
            >
              <ProgressIndicator
                appearance="linear"
                color="colorHpBlue6"
                behavior="determinate"
                value={cleanedProgress}
              />
            </div>

            <div className="flex items-center w-[69px]">
              <p className="caption mr-2 w-[37px] text-right">
                {cleanedProgress >= 0 ? cleanedProgress : 0}%
              </p>
              {cleanedProgress >= 100 ? (
                <IconCheckmarkCircle color="colorGreen6" filled />
              ) : (
                <div className="w-6 h-6"></div>
              )}
            </div>
          </div>
        ) : null}

        {percentage > limit && (
          <div className="flex w-full justify-between items-center my-3">
            <p className="caption w-full">{webValues?.strings?.bar3Title}</p>

            <div
              className="w-full my-1 ml-2 mr-4"
              style={{ borderRadius: "2px", background: "#DBDBDB" }}
            >
              <ProgressIndicator
                appearance="linear"
                color="colorHpBlue6"
                behavior="determinate"
                value={recognizingProgress}
              />
            </div>

            <div className="flex items-center w-[69px]">
              <p className="caption mr-2 w-[37px] text-right">
                {recognizingProgress >= 0 ? recognizingProgress : 0}%
              </p>
              {recognizingProgress >= 100 ? (
                <IconCheckmarkCircle color="colorGreen6" filled />
              ) : (
                <div className="w-6 h-6"></div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
