export const ImprovedVersionItem = ({
  userResponse,
  setUserResponse,
  number,
  value,
}) => {
  return (
    <div
      style={{
        borderRadius: "15px",
        opacity:
          !userResponse.includes(value) === true && userResponse.length === 3
            ? "0.5"
            : "1",
        cursor:
          userResponse.length < 3 || userResponse.includes(value)
            ? "pointer"
            : "default",
        border:
          userResponse.includes(value) === true
            ? "2px solid #4759F5"
            : "2px solid transparent",
        boxShadow: "0px 3px 8px 0px rgba(0, 0, 0, 0.10)",
        marginLeft: number % 2 === 0 ? "8px" : "",
        marginRight: number % 2 === 1 ? "8px" : "",
      }}
      onClick={() => {
        if (!userResponse.includes(value) && userResponse.length < 3) {
          setUserResponse([...userResponse, value]);
        } else if (userResponse.includes(value)) {
          setUserResponse(userResponse.filter((item) => item !== value));
        }
      }}
      className="hover:bg-[#adadad26] flex items-center w-1/2 p-3"
    >
      <img
        src={`/assets/improved-version${number}.svg`}
        className="mr-3 cursor-pointer w-9 h-9 p-1"
        style={{
          cursor:
            userResponse.length < 3 || userResponse.includes(value)
              ? "pointer"
              : "default",
        }}
        alt={value}
      />
      <p
        style={{
          cursor:
            userResponse.length < 3 || userResponse.includes(value)
              ? "pointer"
              : "default",
        }}
        className="text-[#212121]"
      >
        {value}
      </p>
    </div>
  );
};
