export const getDate = (seconds) => {
  const data = new Date(seconds * 1000);
  let dd = data.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  let mm = data.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  const yyyy = data.getFullYear();
  const date = yyyy + "-" + mm + "-" + dd;
  return date;
};

export const getDateInvoice = () => {
  const data = new Date();
  let dd = data.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  let mm = data.getMonth() + 1;
  let month;
  switch (mm) {
    case 1:
      month = "Jan";
      break;
    case 2:
      month = "Feb";
      break;
    case 3:
      month = "Mar";
      break;
    case 4:
      month = "Abr";
      break;
    case 5:
      month = "May";
      break;
    case 6:
      month = "Jun";
      break;
    case 7:
      month = "Jul";
      break;
    case 8:
      month = "Aug";
      break;
    case 9:
      month = "Sep";
      break;
    case 10:
      month = "Oct";
      break;
    case 11:
      month = "Nov";
      break;
    case 12:
      month = "Dec";
      break;

    default:
      month = "";
      break;
  }
  const yyyy = data.getFullYear();
  const date = dd + "-" + month + "-" + yyyy;
  return date;
};

//create function to check if the time is out of working hours
export const checkOutHoursWorkingTime = (
  date,
  startHourLimit,
  endHourLimit
) => {
  startHourLimit = startHourLimit ? startHourLimit : 9;
  endHourLimit = endHourLimit ? endHourLimit : 17;
  const day = date.getDay();
  const hour = date.getHours();
  // Function to check if the current UTC hour is within the specified range
  // const isHourWithinRange = (startHour, endHour) => {
  //   const now = new Date(); // Current date and time in user's local time zone
  //   const utcHour = now.getUTCHours(); // Current UTC hour
  //   // Check if the UTC hour is within the specified range
  //   return utcHour >= startHour && utcHour < endHour;
  // };
  // // Example usage:
  // const startHourLimit = 9; // Start hour (9am UTC)
  // const endHourLimit = 20; // End hour (8pm UTC)
  // // Check if the current UTC hour is within the specified range
  // const isWithinRange = isHourWithinRange(startHourLimit, endHourLimit);
  // console.log(isWithinRange);
  if (day === 0 || day === 6) {
    return true;
  } else if (hour < startHourLimit || hour >= endHourLimit) {
    return true;
  } else {
    return false;
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
